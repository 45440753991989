import React, { useContext, useState } from "react";
import { globalContext } from "../UsersContext";
import Styles from "./_chat.module.css";
import { BsBroadcast } from "react-icons/bs";
import { SocketContext } from "../../../ioContext/socketIo";
import { useEffect } from "react";
import dateFormat from "dateformat";
import AxiosInstance from "../../../api/AxiosInstance";
import { FaBell, FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import { RotatingLines } from "react-loader-spinner";
import handleError from "../ErrorHandler";
import linkifyHtml from "linkify-html";
const Conversation = ({
  batchStudentList,
  batchStdentData,
  setGetmsg,
  setParticularStudent,
  setBatchStdentData,
  batchName,
  groupName,
  messages,
  trainername,
  SetNumber,
  number,
  SetNumber1,
  number1,
  batchCode,
  getmsg,
  setBatchStudentList,
  groupCode,
  numberofStudent,
  groupStudents,
  particularStudent,
  chatedSttudentCount,

  setStop,
  setIsPlaying,
  setRecordedAudio,
  setIsPlaybackActive,
  setIsAudioVisible,
  setRecordedChunks,
  search,
  setSearch,
  HandleSearch,
  HandleClear,
  HandleSearchGroup,
  HandleClearGroup,
  searchgroup,
  setSearchGroup,
  setIsRecording,
  setSort,
  sort,
  setSortGroup,
  sortGroup,
  stopscroll,
  SortFunction,
  setMsgScrollMsgGroup,
  setMsgScroll,
  setMessages,
  lengthStudents,
  lengthStudentsGroup,
  loadingStudents,
  loadingGroupStudents,
  setSelectedMessages,
  setReply,
  setForward,
  setLoadingStudents,
  handleBackClick,
  setActiveSection,
}) => {
  let navigate = useNavigate();
  const socket = useContext(SocketContext);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const [activeItem, setActiveItem] = useState(null);
  // let [search, setSearch] = useState("");
  let { switchComp, setSwitchComp } = useContext(globalContext);
  let [notification, setNotification] = useState({});
  // let [loadmore, SetLoadMore] = useState(false);
  // let [studentList, setStudentList] = useState([]);
  let [newChatslist, setNewchatList] = useState(false);
  // let [updatedstd, setUpdatedstd] = useState([]);
  let [notificationCount, setNotificationCount] = useState(0);
  let [newdata, setNewData] = useState({});

  let [searchTrue, setSearchTrue] = useState(false);

  useEffect(() => {
    socket.on("ischatroom", (data) => {});
    socket.on("notification_new", (data) => {
      setNotification(data);
      let newsudent = {
        username: data?.name,
        _id: data?.msg?.from,
        result: [
          {
            last_msg: data?.msg,
            user_notification: 0,
          },
        ],
      };
      setNewData(newsudent);
    });
  }, [socket]);
  useEffect(() => {
    let existingIndex = batchStudentList.findIndex(
      (obj) => obj?.username === newdata?.username
    );

    if (existingIndex !== -1) {
      batchStudentList[existingIndex] = {
        ...batchStudentList[existingIndex],
      };
    } else {
      if (Object.keys(newdata).length > 0) {
        let std = batchStudentList.push(newdata);

        setBatchStudentList(std);
      }
    }
  }, [notification]);

  useEffect(() => {
    if (particularStudent?.usernoti > 0) {
      if (batchName) {
        socket.emit("msgRead_new", {
          senderId: user._id,
          role: user.role1,
          receiverId: particularStudent?.studentId,
          seen: true,
          code: batchName,
          count: notificationCount,
          type: "batch",
          device: "web",
        });
        setNotificationCount(0);
      } else if (groupName) {
        socket.emit("msgRead_new", {
          senderId: user._id,
          role: user.role1,
          receiverId: particularStudent?.studentId,
          seen: true,
          code: groupName,
          count: notificationCount,
          type: "group",
          device: "web",
        });
        setNotificationCount(0);
      }
    } else {
    }
  }, [particularStudent?.studentId && getmsg]);

  let NewChats = async () => {
    setNewchatList(true);
    const res = await AxiosInstance.post(
      `/users/batch_group_students/?search=${search}`,
      {
        batchId_groupId: trainername._id,
      },
      {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      }
    )
      .then((x) => {
        let data2 = x?.data?.studentdata;
        const newArray = batchStudentList
          .concat(data2)
          .filter((obj, index, arr) => {
            return index === arr.findIndex((o) => o.username === obj.username);
          });
        setBatchStdentData(newArray);

        if (batchName) {
          SetNumber(newArray.length);
        } else {
          SetNumber1(newArray.length);
        }
        // setStudentList(newArray);
      })
      .catch((y) => {
        if (y?.response?.status === 403) {
          handleError(navigate, socket);
        }
      });
  };

  useEffect(() => {
    let data1 =
      batchStudentList.length > 0 &&
      batchStudentList?.map((y) => {
        // //(y);
        return y?._id === notification?.msg?.from
          ? {
              ...y,
              result: [
                {
                  last_msg: notification?.msg,
                  user_notification: y?.result[0]?.user_notification + 1,
                },
              ],
            }
          : { ...y };
      });

    setBatchStudentList(data1);
  }, [notification]);

  useEffect(() => {
    let data1 = batchStudentList?.map((x) => {
      return x?.username
        ? x._id == messages?.slice(-1)[0]?.to
          ? {
              ...x,
              result: [
                {
                  last_msg: messages?.slice(-1)[0],
                  user_notification: 0,
                },
              ],
            }
          : { ...x }
        : "";
    });
    // Find the index of the modified object
    const index = data1.findIndex((x) => {
      return x?.username && x._id === messages?.slice(-1)[0]?.to;
    });

    // Check if the condition is met
    if (index !== -1) {
      // Splice the object from the array
      const modifiedObject = data1?.splice(index, 1)[0];

      // Unshift the modified object back into the array
      data1.unshift(modifiedObject);
    }
    const finalData = data1[0] === "" ? [] : data1;
    setBatchStudentList(data1);
    setBatchStdentData(finalData);
  }, [messages, newChatslist]);

  let handleNotification = (sid) => {
    let data1 = batchStudentList?.map((x) => {
      if (x?.result[0]?.user_notification > 0) {
        setNotificationCount(x?.result[0]?.user_notification);
      }

      return x._id === sid
        ? x?.result[0]?.user_notification > 0
          ? {
              ...x,

              result: [
                {
                  last_msg: x?.result[0]?.last_msg,
                  user_notification: 0,
                },
              ],
            }
          : { ...x }
        : x;
    });
    const result = data1.some(
      (obj) =>
        obj?.result[0]?.user_notification > 0 &&
        (!obj.hasOwnProperty("usersNotification") ||
          typeof obj?.result[0]?.user_notification !== "number")
    );

    if (result) {
    } else {
      if (batchName) {
        socket.emit("batchNotification", {
          userId: user?._id,
          role: user?.role1,
          batchCode: batchCode,
          device: "web",
        });
      } else if (groupName) {
        socket.emit("batchNotification", {
          userId: user?._id,
          role: user?.role1,
          groupCode: groupName,
          device: "web",
        });
      }
    }

    setBatchStudentList(data1);
  };

  let d = Date.now();
  let today = dateFormat(d, "mmm d, yyyy");

  let Loadmore = () => {
    SetNumber(number + lengthStudents);
  };

  let Loadmoregroup = () => {
    SetNumber1(number1 + lengthStudentsGroup);
  };

  let handleChange = (e) => {
    if (batchCode) {
      setSearch(e.target.value?.toLowerCase());
    } else {
      setSearchGroup(e.target.value?.toLowerCase());
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (batchCode) {
        if (search?.length > 0) {
          HandleSearch(search);
          setSearchTrue(true);
          SetNumber(0);
          setLoadingStudents(false);
        }
      } else {
        if (searchgroup?.length > 0) {
          HandleSearchGroup(searchgroup);
          setSearchTrue(true);
          SetNumber1(0);
        }
      }
    }
  };
  const handleBackspaceUser = (event) => {
    if (batchCode) {
      if (event.key === "Backspace" && search?.length === 1) {
        HandleClear();
        setSearchTrue(false);
        setBatchStdentData([]);
      }
    } else {
      if (event.key === "Backspace" && searchgroup?.length === 1) {
        HandleClearGroup();
        setSearchTrue(false);
        setBatchStdentData([]);
      }
    }

    // if (event.key === "Backspace" && search?.length === 1) {
    //   if (batchCode) {
    // HandleClear();
    // setSearchTrue(false);
    // setBatchStdentData([]);
    //   } else {
    // HandleClearGroup();
    // setSearchTrue(false);
    // setBatchStdentData([]);
    //   }
    // }
  };

  let HandleClickSearch = () => {
    if (batchCode) {
      if (search?.length > 0) {
        HandleSearch(search);
        setSearchTrue(true);
        SetNumber(0);
        setLoadingStudents(false);
      }
    } else {
      if (searchgroup?.length > 0) {
        HandleSearchGroup(searchgroup);
        setSearchTrue(true);
        SetNumber1(0);
      }
    }
  };
  let HandleClearSearch = () => {
    if (batchCode) {
      HandleClear();
      setSearchTrue(false);
      setBatchStdentData([]);
    } else {
      HandleClearGroup();
      setSearchTrue(false);
      setBatchStdentData([]);
    }
  };
  let HandleNotificationSort = () => {
    if (sort === true) {
      SetNumber(0);

      setSort(false);
    } else {
      SetNumber(0);

      setSort(true);
    }
  };
  let HandleNotificationSortGroup = () => {
    if (sortGroup === true) {
      SetNumber1(0);

      setSortGroup(false);
    } else {
      SetNumber1(0);

      setSortGroup(true);
    }
  };
  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }
  return (
    <>
      <aside style={{ borderBottom: "1px solid gray" }}>
        <div className={Styles.Head}>
          {batchName ? (
            <h4 className={Styles.batchname}>{batchName}</h4>
          ) : (
            <h4 className={Styles.batchname}>{groupName}</h4>
          )}
          {switchComp ? (
            <button
              title={
                groupStudents == 0
                  ? "Students are not present in this group"
                  : ""
              }
              disabled={groupStudents == 0}
              onClick={(e) => {
                setSwitchComp(false);
                socket.emit("chatwith", {
                  senderId: user._id,
                  role: user.role1,
                  receiverId: batchName,
                  device: "web",
                });
              }}
              className={Styles.Broadcast}>
              <span>
                <BsBroadcast></BsBroadcast>
              </span>{" "}
              Broadcast
            </button>
          ) : (
            ""
          )}
        </div>
        <div className={Styles.NewStudents}>
          <div>
            {" "}
            No of Students -{" "}
            {numberofStudent
              ? numberofStudent
              : groupStudents
              ? groupStudents
              : 0}
          </div>
          {batchName ? (
            <div className={Styles.NotySort} onClick={HandleNotificationSort}>
              <FaBell />
            </div>
          ) : (
            <div
              className={Styles.NotySort}
              onClick={HandleNotificationSortGroup}>
              <FaBell />
            </div>
          )}
        </div>
      </aside>
      <div>
        <div className={Styles.chatMenuWrapper}>
          <div className={Styles.SearchBlock}>
            <div className={Styles.SearchArticle}>
              <span className={Styles.SearchText}>
                <input
                  name="search"
                  type="text"
                  value={batchName ? search : searchgroup}
                  placeholder="Search student"
                  onChange={(e) => handleChange(e)}
                  onKeyPress={handleKeyPress}
                  onKeyDown={handleBackspaceUser}
                />
              </span>
              {searchTrue === false ? (
                <button
                  className={Styles.searchIcon}
                  onClick={HandleClickSearch}>
                  <FiSearch />
                </button>
              ) : (
                <button
                  className={Styles.searchCancel}
                  onClick={HandleClearSearch}>
                  <ImCross />
                </button>
              )}
            </div>
          </div>
          {loadingStudents || loadingGroupStudents ? (
            <div className={Styles.loader}>
              <RotatingLines
                strokeColor="#f16f1d"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          ) : (
            <div
              className={Styles.ConversationBlock}
              id="scrollableDiv"
              style={{ height: 550, overflow: "auto" }}>
              <>
                <InfiniteScroll
                  dataLength={batchStudentList?.length}
                  next={batchName ? Loadmore : Loadmoregroup}
                  // hasMore={batchStudentList?.length < numberofStudent + 50}
                  hasMore={
                    batchName
                      ? number < numberofStudent
                      : number1 < groupStudents
                  }
                  // loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv">
                  {batchStudentList?.length === 0 ? (
                    <p style={{ textAlign: "center" }}>No Data Found</p>
                  ) : (
                    batchStudentList?.length > 0 &&
                    batchStudentList
                      .sort((a, b) => {
                        return !sort
                          ? new Date(
                              b?.result[0]?.last_msg?.created_at
                            ).getTime() -
                              new Date(
                                a?.result[0]?.last_msg?.created_at
                              ).getTime()
                          : 0; // You can modify this condition based on your sorting needs
                      })
                      .map((students, ind) => {
                        return (
                          <div
                            className={Styles.BlockConersation}
                            key={ind}
                            style={{
                              backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              border: "0.3px ridge #999",
                              borderRadius: "6px",
                              marginBottom: "5px",
                            }}>
                            <div
                              className={Styles.listUser}
                              key={students._id}
                              onClick={(e) => {
                                setActiveItem(e);
                                setParticularStudent({
                                  studentId: students?._id,
                                  studentName: students?.username,
                                  notification:
                                    students?.result[0]?.user_notification > 0,
                                  notiCount:
                                    students?.result[0]?.student_notification ||
                                    0,
                                  usernoti:
                                    students?.result[0]?.user_notification || 0,
                                });
                                // setGetmsg(false);

                                handleNotification(students?._id);
                                setStop(false);
                                setIsPlaying(false);
                                setRecordedAudio(null);
                                setIsPlaybackActive(false);
                                setIsAudioVisible(false); // Hide the audio tag
                                setRecordedChunks([]); // Clear the recorded audio chunks
                                setIsPlaying(false);
                                setIsRecording(false);
                                setSelectedMessages([]);
                                setReply(false);
                                setForward(false);
                                setActiveSection("");
                                if (
                                  particularStudent?.studentId !==
                                  particularStudent?.studentId
                                ) {
                                  setMessages([]);
                                }
                                if (batchName) {
                                  setMsgScroll(0);
                                  handleBackClick();
                                } else {
                                  setMsgScrollMsgGroup(0);
                                  handleBackClick();
                                }
                              }}>
                              <div className={Styles.conversation}>
                                {students?.username?.length > 25 ? (
                                  students?.username?.slice(0, 25) +
                                  " " +
                                  ". . ."
                                ) : (
                                  <span>
                                    {students?.username} -{" "}
                                    {students?.number
                                      ?.toString()
                                      ?.substring(6, 10)}
                                  </span>
                                )}
                                <div
                                  className={
                                    students?.result[0]?.user_notification
                                      ? students?.result[0]
                                          ?.user_notification !== 0 &&
                                        students.username
                                        ? `${Styles.notify}`
                                        : `${Styles.nonotify}`
                                      : ""
                                  }>
                                  <span>
                                    {students?.result[0]?.user_notification != 0
                                      ? students?.result[0]?.user_notification
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <div className={Styles.tickmark}>
                                <div>
                                  <p
                                    className={
                                      students.lastmsg?.text
                                        ? `${Styles.lastmsg}`
                                        : `${Styles.Nolastmsg}`
                                    }>
                                    {batchStudentList?.length > 0 && (
                                      <span>
                                        {students?.result.length > 0 ? (
                                          students?.result[0]?.last_msg
                                            .files ? (
                                            `${students?.result[0]?.last_msg.files?.originalname.slice(
                                              0,
                                              15
                                            )}(File)`
                                          ) : students?.result[0]?.last_msg
                                              .html ? (
                                            <p
                                              className={Styles.textEditStyle}
                                              style={{ fontSize: "11px" }}>
                                              New Message....
                                            </p>
                                          ) : students?.result[0]?.last_msg
                                              ?.text?.length > 20 ? (
                                            students?.result[0]?.last_msg?.text?.slice(
                                              0,
                                              20
                                            ) +
                                            " " +
                                            ". . ."
                                          ) : (
                                            students?.result[0]?.last_msg?.text
                                          )
                                        ) : (
                                          <span>No recent message</span>
                                        )}
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <span className={Styles.time}>
                                  {students?.result[0]?.last_msg?.created_at ? (
                                    dateFormat(
                                      students?.result[0]?.last_msg?.created_at,
                                      "mmm d, yyyy"
                                    ) === today ? (
                                      dateFormat(
                                        students?.result[0]?.last_msg
                                          ?.created_at,
                                        "DDDD h:MM TT"
                                      )
                                    ) : (
                                      <Moment format="MMM DD - h:mm a">
                                        {
                                          students?.result[0]?.last_msg
                                            ?.created_at
                                        }
                                      </Moment>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                            {user.role1 === "hr" ||
                              (user.role1 == "human resource" && (
                                <div className={Styles.HrBlock}>
                                  <Link
                                    to={`/user-dashboard/studentprofile/${students?.number}`}>
                                    <span>
                                      <FaUserCircle />
                                    </span>
                                  </Link>
                                </div>
                              ))}
                          </div>
                        );
                      })
                  )}
                </InfiniteScroll>
                {batchName &&
                batchStudentList?.length < numberofStudent &&
                search?.length <= 0 &&
                sort === false ? (
                  <div className={Styles.loadmoreBlock}>
                    {number < numberofStudent ||
                    (lengthStudents != 0 &&
                      lengthStudents < 50 &&
                      lengthStudents < numberofStudent) ? (
                      // (!stopscroll && number < numberofStudent) ||
                      //   number !== numberofStudent
                      <button onClick={NewChats}>Student List</button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : groupName &&
                  batchStudentList?.length < groupStudents &&
                  searchgroup?.length <= 0 ? (
                  <div className={Styles.loadmoreBlock}>
                    {number1 < groupStudents ||
                    (lengthStudentsGroup != 0 &&
                      lengthStudentsGroup < 50 &&
                      lengthStudentsGroup < groupStudents) ? (
                      // (!stopscroll && number < numberofStudent) ||
                      //   number !== numberofStudent
                      <button onClick={NewChats}>Student List</button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            </div>
          )}

          {/* <div className={Styles.loadmoreBlock}>
            {batchName ? (
              batchStudentList?.length < numberofStudent ||
              (batchStudentList?.length < batchStudentList?.length &&
                number + batchStudentList?.length < numberofStudent) ? (
                ""
              ) : (
                ""
              )
            ) : (groupStudents < batchStudentList?.length &&
                batchStudentList?.length < groupStudents) ||
              (batchStudentList?.length < batchStudentList?.length &&
                number1 + batchStudentList?.length < groupStudents) ? (
              <button onClick={NewChats}>Student List</button>
            ) : (
              ""
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};
{
  /* <button onClick={NewChats}>Student List</button> */
}

export default Conversation;
