import React from "react";
import Styles from "./_UserToUser.module.css";
import {
  BsChatDots,
  BsChatSquareTextFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
  BsFillPeopleFill,
  BsPlusCircleFill,
  BsFillBarChartFill,
} from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { BiExit, BiPlusMedical } from "react-icons/bi";
import { FaUserCircle, FaUsers, FaPlus, FaMinus } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import { useState } from "react";
import { useEffect } from "react";
import { socket } from "../../../ioContext/socketIo";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import UserMessage from "./UserMessage";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import dateFormat from "dateformat";
import Select from "react-select";
import wavEncoder from "wav-encoder";

import {
  Tooltip,
  Whisper,
  Modal,
  ButtonToolbar,
  Button,
  Dropdown,
  DatePicker,
  Toggle,
} from "rsuite";

import { ImAttachment } from "react-icons/im";
import FileUpload from "./FileUpload";
import { AiFillDelete } from "react-icons/ai";
import { Fragment } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import handleError from "../ErrorHandler";
const UserList = () => {
  let navigate = useNavigate();
  let scrollRef = useRef();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [online, setOnline] = useState();

  let [searchTrue, setSearchTrue] = useState(false);
  let [branchUserList, setBranchUserList] = useState([]);
  let [branchUserClicked, setBranchUserClicked] = useState(false);

  let [conversationList, setConversationList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  let [loadingmsg, setLoadingMsg] = useState(false);

  let [convo, setConvo] = useState([]);
  let [particularStudent, setParticularStudent] = useState({});

  let [selectedUser, setSelectedUser] = useState({});

  let [message, setMessage] = useState([]);
  // let [notificationCount, setNotificationCount] = useState(0);
  let [notification, setNotification] = useState({});
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);
  let d = Date.now();
  let today = dateFormat(d, "mmm d, yyyy");
  let [loading, setLoading] = useState(false);

  // voice recoder//

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  // const [recordingStatus, setRecordingStatus] = useState("");
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  // const [audioURL, setAudioURL] = useState("");
  const [recordedAudio, setRecordedAudio] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [NonAddedUser, setNonAddedUser] = useState([]);
  const [GroupUsers, setGroupUsers] = useState([]);
  const [groupName, setGroupName] = useState("");
  // const [backdrop, setBackdrop] = React.useState("static");
  let [groupNameTrue, setGroupNameTrue] = useState(false);
  let forwardmsg = [];
  let [selected, setSelected] = useState(false);

  let [search, setSearch] = useState("");
  let [searchapi, setSearchApi] = useState("");
  let [searchUser, setSearchUser] = useState("");

  let [clear, setClear] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  let [adminUser, setAdminUser] = useState([]);
  let [groupInfoUpdate, setGroupInfoUpdate] = useState(false);

  let [userClicked, setUserListClicked] = useState(false);

  let [groupinfo, setGroupInfo] = useState([]);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const [reply, setReply] = useState(false);
  const [forward, setForward] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const [openPoll, setOpenPoll] = React.useState(false);
  const [poll, setPoll] = useState([
    {
      question: "",
      options: [""],
      toggleValue: false,
      toggleValue1: false,
      selectedDate: null,
    },
  ]);
  const [errors, setErrors] = useState({});

  const iconRef = useRef(null);

  const toggleOptions = () => {
    setAreOptionsVisible(!areOptionsVisible);
  };

  const handleClickOutside = (event) => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setAreOptionsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setGroupName(selectedUser?.username);
    setSidebarOpen(!sidebarOpen);

    fetchGroupInfo();
  };
  let fetchGroupInfo = () => {
    if (selectedUser?.id !== undefined) {
      async function fetchlist() {
        // setLoading(true);
        await AxiosInstance.get(
          `/employee/users_groupdata?group_id=${selectedUser?.id}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        )
          .then((data) => {
            let data1 = data?.data?.groupdata;

            let sss = data1.usersList.filter((x) => {
              return x.mongoid === user._id;
            });

            data1.usertype = sss[0]?.type;

            setGroupInfo(data1);
          })
          .catch((x) => {
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
      fetchlist();
    }
  };
  const handleOpen = () => {
    setOpen(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };
  const handleOpenPoll = () => {
    setOpenPoll(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClosePoll = () => {
    // setImagePreview(null);
    setOpenPoll(false);
    setPoll([
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
    setErrors({});
  };

  const isFormValid = () => {
    const newErrors = {};

    for (let i = 0; i < poll.length; i++) {
      if (!poll[i].question) {
        newErrors[`question_${i}`] = "Question is required";
      }
      // if (poll[i].options.some(option => !option)) {
      //   newErrors[`options_${i}`] = "Add options";
      // }
      const emptyOptions = poll[i].options.filter((option) => !option.trim());

      if (emptyOptions.length > 0) {
        newErrors[`options_${i}`] = "All options must be filled";
      }
      if (poll[i].options.length == 1) {
        newErrors[`options_${i}`] = "Add  atleast 2 options ";
      }
      if (!poll[i].selectedDate) {
        newErrors[`date_${i}`] = "Date is required";
      }
    }

    // Clear errors for fields that are now valid
    for (let i = 0; i < poll.length; i++) {
      if (poll[i].question) {
        delete newErrors[`question_${i}`];
      }
      // if (!poll[i].options.some(option => !option)) {
      //   delete newErrors[`options_${i}`];
      // }
      // if (!poll[i].options.length == 1) {
      //  delete newErrors[`options_${i}`];
      // }
      if (poll[i].selectedDate) {
        delete newErrors[`date_${i}`];
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const addDiv = () => {
    setPoll([
      ...poll,
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
  };
  const deleteDiv = (index) => {
    const newQuestionAnswers = [...poll];
    newQuestionAnswers.splice(index, 1);
    setPoll(newQuestionAnswers);
  };
  const handleDateChange = (index, newDate) => {
    const updatedPoll = [...poll];
    // Convert the newDate to ISO string format
    updatedPoll[index].selectedDate = newDate?.toISOString();
    setPoll(updatedPoll);
  };

  const handleToggleChange1 = (index) => {
    const updatedPoll = [...poll];
    updatedPoll[index].toggleValue1 = !updatedPoll[index].toggleValue1;
    setPoll(updatedPoll);
  };
  const handleToggleChange = (pollIndex) => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].toggleValue = !updatedPoll[pollIndex].toggleValue;
    setPoll(updatedPoll);
  };
  const handleOptionChange = (index, ind, newOption) => {
    const updatedPoll = [...poll];
    updatedPoll[index].options[ind] = newOption;
    setPoll(updatedPoll);
  };

  const handleAddOption = (pollIndex) => {
    // //(pollIndex);
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].options = [...updatedPoll[pollIndex].options, ""];
    // //(updatedPoll);
    setPoll(updatedPoll);
  };
  const handleClearOption = (index, ind) => {
    const updatedPoll = [...poll];
    // //(updatedPoll);
    updatedPoll[index].options.splice(ind, 1);
    setPoll(updatedPoll);
  };
  const handleQuestionChange = (pollIndex, newQuestion) => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].question = newQuestion;
    setPoll(updatedPoll);
  };

  const handleSubmit = () => {
    // //(errors);
    setErrors({});

    // You can implement your logic to send the poll data here
    // //(poll);
    if (!isFormValid()) {
      const newErrors = {};

      for (let i = 0; i < poll.length; i++) {
        if (!poll[i].question) {
          newErrors[`question_${i}`] = "Question is required";
        }
        if (poll[i].options.some((option) => !option)) {
          newErrors[`options_${i}`] = "Add options";
        }
        if (poll[i].options.length == 1) {
          newErrors[`options_${i}`] = "Add  atleast 2 options ";
        }
        if (!poll[i].selectedDate) {
          newErrors[`date_${i}`] = "Date is required";
        }
      }

      setErrors(newErrors);
    } else {
      const currentDate = new Date();
      let errors = false;
      const newErrors = {};
      let pollind = 0;
      for (const ele of poll) {
        const selectedDate = new Date(ele?.selectedDate);
        let a = currentDate.getTime();
        let b = selectedDate.getTime();
        if (a > b) {
          newErrors[`date_${pollind}`] = "Kindly extend the date or time.";
          a++;
          errors = true;
          break;
        } else {
          a++;
        }
      }
      const currentDateTime = currentDate.toISOString();
      console.log({ errors });
      if (errors) {
        setErrors(newErrors);
        return;
      }
      // //("From Submit");
      let payload = poll.map((ele, ind) => {
        return {
          pollcreaterid: user?._id,
          poll_question: ele?.question,
          options: ele?.options,
          startTime: currentDateTime,
          endTime: ele?.selectedDate,
          stu_view_vote: ele?.toggleValue1,
          vote_type: ele?.toggleValue,
        };
      });
      // //(payload);
      async function students() {
        await AxiosInstance.post(`/employee/users_pollcreate`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then((x) => {
            let data = x?.data;
            // //(data);
            let poll1 = data?.map((ele, ind) => {
              const date = new Date(ele?.endTime);

              // Extract date and time components
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's 0-based
              const day = String(date.getDate()).padStart(2, "0");
              const hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");

              // Create the desired format
              const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
              let time = formattedDate;
              // //(time);
              return {
                _id: ele._id,
                poll_question: ele.poll_question,
                endTime: ele.endTime,
                stu_view_vote: ele.stu_view_vote,
                user_id: user._id,
                time: time,
              };
            });
            // //(poll1);

            let aaa = poll1.map((x) => {
              if (selectedUser.group === false) {
                return reply
                  ? socket.emit(
                      "users_user_chat",
                      {
                        senderId: user._id,
                        receiverId: particularStudent?.studentid,
                        text: x.poll_question,
                        role: user?.role1,

                        device: "web",
                        poll: x,
                        replayback: {
                          message: selectedMessages[0]?.messages?.text
                            ? selectedMessages[0]?.messages?.text
                            : "",
                          file: selectedMessages[0]?.messages?.files
                            ? selectedMessages[0]?.messages?.files
                            : "",
                        },
                      },
                      function callback(msg) {
                        setReply(false);
                        setSelectedMessages([]);
                        msg.forEach((msg) => {
                          setMessage((prevMessages) =>
                            prevMessages.concat(msg)
                          );
                        });
                      }
                    )
                  : new Promise((resolve, reject) => {
                      socket.emit(
                        "users_user_chat",
                        {
                          senderId: user._id,
                          receiverId: particularStudent?.studentid,
                          text: x.poll_question,
                          role: user?.role1,

                          device: "web",
                          poll: x,
                        },
                        function callback(msg) {
                          // Do any processing you need with msg here
                          resolve(msg);
                        }
                      );
                    });
              } else if (selectedUser.group === true) {
                return reply
                  ? socket.emit(
                      "users_group",
                      {
                        senderId: user?._id,
                        sendername: user?.username,
                        groupId: selectedUser?.id,
                        groupname: selectedUser?.username,
                        text: x.poll_question,
                        role: user?.role1,
                        index: 0,

                        poll: x,
                        //  code: batchName,
                        device: "web",
                        status: null,
                        replayback: {
                          message: selectedMessages[0]?.messages?.text
                            ? selectedMessages[0]?.messages?.text
                            : "",
                          file: selectedMessages[0]?.messages?.files
                            ? selectedMessages[0]?.messages?.files
                            : "",
                        },
                      },
                      function callback(msg) {
                        setReply(false);
                        setSelectedMessages([]);
                        // Do any processing you need with msg here
                        msg.forEach((msg) => {
                          setMessage((prevMessages) =>
                            prevMessages.concat(msg)
                          );
                        });
                      }
                    )
                  : new Promise((resolve, reject) => {
                      socket.emit(
                        "users_group",
                        {
                          senderId: user?._id,
                          sendername: user?.username,
                          groupId: selectedUser?.id,
                          groupname: selectedUser?.username,
                          text: x.poll_question,
                          role: user?.role1,
                          index: 0,

                          poll: x,
                          //  code: batchName,
                          device: "web",
                          status: null,
                        },
                        function callback(msg) {
                          // Do any processing you need with msg here
                          resolve(msg);
                        }
                      );
                    });
              }
            });
            Promise.all(aaa)
              .then((message) => {
                // //(message, "promis");
                message.forEach((msg) => {
                  setMessage((prevMessages) => prevMessages.concat(msg));
                });
              })
              .catch((error) => {
                console.error("Error:", error);
                // Handle any errors that may occur during the socket.emit calls
              });
            // //(aa);
          })
          .catch((x) => {
            handleClosePoll();
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            handleClosePoll();
          });
      }
      students();
    }
  };
  const disablePastDate = (date) => {
    const today = new Date();
    // Set the time to midnight for comparison
    today.setHours(0, 0, 0, 0);

    return date < today;
  };
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  useEffect(() => {
    const handleNotification = (data) => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification_new", handleNotification);

    return () => {
      socket.off("notification_new", handleNotification);
    };
  }, []);
  const handleOpenUpdate = async () => {
    setOpenUpdate(true);
    await AxiosInstance.get(`/employee/users_list`, {
      headers: {
        Authorization: `Bearer ${user.TOKEN}`,
      },
    })
      .then((data) => {
        let list = data?.data?.UsersList;
        let finaldata = list.map((ele, ind) => {
          return {
            id: ele._id,
            username: ele.username,
            role: ele.role1,
            number: ele.number.map((x) => x),
            branch: ele.branch,
            group: false,
          };
        });

        const usernamesArray2 = groupinfo?.usersList?.map(
          (obj) => obj.username
        );

        const filteredArray1 = finaldata?.filter(
          (obj) => !usernamesArray2.includes(obj.username)
        );

        let eee = filteredArray1.map((x) => {
          return {
            value: x,
            label: x.username,
          };
        });
        setNonAddedUser(eee);
      })
      .catch((x) => {
        if (x?.response?.status === 403) {
          handleError(navigate, socket);
        }
      });
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  let HandleSearch = (e) => {
    setSearchApi(e);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);
  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      const audioData = event.data;
      //(event.data);
      //(audioData);
      setRecordedAudio(audioData);
      setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
    }
  };
  // //(recordedAudio);
  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);
    //(recordedAudio);
    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive((prevPlaybackState) => !prevPlaybackState);
    setIsIconDisabled(true); // Disable the icon when clicked
    // Clear recorded chunks when playback is triggered
    // setRecordedChunks([]);
  };
  let HandleMakeAdmin = () => {
    let payload = {
      group_id: selectedUser?.id,
      usertype: "admin",
      id: user?._id,
      type: "gutu",
      mongoid: adminUser?.mongoid,
    };

    async function students() {
      await AxiosInstance.post(`/employee/users_groupupdate`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          handleCloseEdit();
          // setGroupInfoAdmin(true);
          fetchGroupInfo();
          setGroupInfoUpdate(true);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };
  let HandleRemoveFromAdmin = () => {
    let payload = {
      group_id: selectedUser?.id,
      usertype: "user",
      id: user?._id,
      type: "gutu",
      mongoid: adminUser?.mongoid,
    };

    async function students() {
      await AxiosInstance.post(`/employee/users_groupupdate`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          handleCloseEdit();
          fetchGroupInfo();
          setGroupInfoUpdate(true);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };
  let HandleRemoveUser = () => {
    let payload = {
      group_id: selectedUser?.id,
      id: user?._id,
      type: "ru",
      usersList: [{ _id: adminUser?.mongoid, username: adminUser?.username }],
    };

    async function students() {
      await AxiosInstance.post(`/employee/users_groupupdate`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          handleCloseEdit();
          fetchGroupInfo();
          setGroupInfoUpdate(true);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };
  const items = [
    <Dropdown.Item key={1} onClick={HandleRemoveUser}>
      Remove user
    </Dropdown.Item>,
    <Dropdown.Item key={2} onClick={HandleMakeAdmin}>
      Make group admin
    </Dropdown.Item>,
  ];
  const items1 = [
    <Dropdown.Item key={1} onClick={HandleRemoveUser}>
      Remove user
    </Dropdown.Item>,
    <Dropdown.Item key={2} onClick={HandleRemoveFromAdmin}>
      Dismiss as admin
    </Dropdown.Item>,
  ];
  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);
  // //("object");
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
    //(audioBlob);

    return URL.createObjectURL(audioBlob);
  };
  // //(isPlaybackActive);

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
      // className={!stop ? `${Styles.AudioNot}` : `${Styles.Audio}`}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = (audioBlob) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };

  let HandleAudio = async (e) => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = (arrayBuffer) => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );
      if (wavFile !== "") {
        //("helllooooooo");
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          //(payload);
          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res?.data?.files;
          //("object");
          if (selectedUser?.group === false) {
            socket.emit(
              "users_user_chat",
              {
                files: filesData,
                senderId: user?._id,
                receiverId: particularStudent?.studentid,
                role: user?.role1,
                // code: batchCode,
                // type: batchCode ? "batch" : "group",
                device: "web",
              },
              function callback(msg) {
                //(msg);
                msg.tick = false;
                setMessage([...message, msg]);
                setIsPlaybackActive(false);
                setIsAudioVisible(false); // Hide the audio tag
                setRecordedChunks([]); // Clear the recorded audio chunks
                //("end");
                setIsPlaying(false);
              }
            );
            setIsAudioVisible(false); // Hide the audio tag
            setRecordedChunks([]); // Clear the recorded audio chunks
            //("asdasdasda");
            setIsPlaying(false);
          } else if (selectedUser?.group === true) {
            socket.emit(
              "users_group",
              {
                files: filesData,
                senderId: user?._id,
                groupId: selectedUser?.id,
                groupname: selectedUser?.username,
                role: user?.role1,
                index: 0,
                device: "web",
                status: null,
              },
              function callback(msg) {
                //(msg);
                msg.tick = false;
                setMessage([...message, msg]);
                setIsPlaybackActive(false);
                setIsAudioVisible(false); // Hide the audio tag
                setRecordedChunks([]); // Clear the recorded audio chunks
                //("end");
                setIsPlaying(false);
              }
            );
            setIsAudioVisible(false); // Hide the audio tag
            setRecordedChunks([]); // Clear the recorded audio chunks
            //("asdasdasda");
            setIsPlaying(false);
          }
        } catch (error) {}
      } else {
      }
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  useEffect(() => {
    async function fetchlist() {
      const searchVal = search ? `?search=${search}` : "";
      await AxiosInstance.get(`/employee/users_coneverstions${searchVal}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then((data) => {
          let list = data?.data?.findUserslist;

          setConversationList(list);
        })

        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  }, [searchapi, clear, groupNameTrue, userClicked]);

  useEffect(() => {
    if (conversationList?.length > 0) {
      let data1 = conversationList.map((ele, ind) => {
        let usernamesSatisfyingCondition = [];
        let usernamesNotSatisfyingCondition = [];
        let groupNoty = [];
        let usersid = [];
        let read = [];
        let role = [];
        if (ele.group === false) {
          // message.push(ele?.groupchat?.messages?.text);
          ele?.conversation?.forEach((userObj) => {
            if (userObj?.username !== user?.username) {
              if (userObj.id) {
                usersid.push(userObj.id);
                // usersid.push(userObj.id);
              } else {
                usersid.push(userObj.id1);
              }
              // usersid.push(userObj.id || userObj.id1 !== user._id);
              usernamesSatisfyingCondition.push(userObj.username);
              role.push(userObj.role);
              read.push(userObj.notification);
              // usersid.push(userObj.id1 ? userObj.id1 : "");
              // usersid.push(userObj.id1);
            } else {
              usernamesNotSatisfyingCondition.push(userObj.notification);
            }
          });
        } else {
          ele.usersList.forEach((x) => {
            if (x?.mongoid === user?._id) {
              groupNoty.push(x.notification);
            }
          });
        }
        return {
          satisfying: usernamesSatisfyingCondition,
          notSatisfying: usernamesNotSatisfyingCondition,
          messagetext: ele?.groupchat?.messages?.text
            ? ele?.groupchat?.messages?.text
            : "",
          messagefiles: ele?.groupchat?.messages?.files
            ? ele?.groupchat?.messages?.files
            : "",
          createdAt: ele?.groupchat?.createdAt,
          gorupName: ele?.group === true ? ele?.groupName : "",
          groupNotyfy: groupNoty[0],
          groupid: ele?.group === true ? ele._id : "",
          userid: usersid,
          group: ele.group,
          role: role.flat(Infinity),
          usersList: ele.group === true ? ele.usersList : "",
        };
      });

      const newData = data1.map((x) => {
        return {
          username: x.gorupName !== "" ? x.gorupName : x.satisfying[0],
          notification: x.notSatisfying[0],
          groupNotification: x.groupNotyfy,
          messagetext: x.messagetext ? x.messagetext : "",
          messagefiles: x.messagefiles ? x.messagefiles : "",
          createdAt: x.createdAt,
          id: x.groupid !== "" ? x.groupid : x.userid[0],
          group: x.group,
          UserList: x.usersList,
          role: x?.role ? x.role[0] : "",
          // groupName: x.groupName,
        };
      });

      setConvo(newData);
    }
  }, [conversationList]);

  useEffect(() => {
    if (
      user._id &&
      particularStudent?.studentid !== undefined &&
      selectedUser?.group === false
    ) {
      async function fetchlist() {
        try {
          setLoadingMsg(true);

          let device = Cookies.get("device");

          socket.emit(
            "isOnline",
            {
              senderId: user?._id,
              role: user?.role1,
              receiverId: particularStudent?.studentid,
              device: "web",
              deviceId: device,
            },
            function callback(msg) {
              setOnline(msg);
            }
          );

          socket.emit("chatwith", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent?.studentid,
            // code: batchCode,
            device: "web",
            deviceId: device,

            // deviceId
          });
          let x = await AxiosInstance.get(
            `/employee/users_userchat?id=${user?._id}&id1=${particularStudent?.studentid}`,
            {
              headers: {
                Authorization: `Bearer ${user.TOKEN}`,
              },
            }
          );

          x?.data?.data?.groupchat?.reverse();
          let data = x?.data?.data?.groupchat;

          let noty = x?.data?.data?.conversation;
          let a = noty.filter((x) => {
            return x.username !== user.username;
          });
          if (x?.data?.data?.groupchat) {
            for (
              let index = 0;
              index < x?.data?.data?.groupchat?.length;
              index++
            ) {
              const element = x?.data?.data?.groupchat[index];
              if (!element?.hasOwnProperty("poll")) {
                element.poll = null; // or set it to an initial value as needed
              }
            }
          }
          for (let index = 1; index <= a[0]?.notification; index++) {
            const ele =
              index > x?.data?.data?.groupchat?.length
                ? x?.data?.data?.groupchat[x?.data?.data?.groupchat?.length - 1]
                : x?.data?.data?.groupchat[
                    x?.data?.data?.groupchat?.length - index
                  ];
            ele.tick = false;
          }
          setMessage(data);
          setLoadingMsg(false);
        } catch (error) {
          if (error?.response?.status === 403) {
            handleError(navigate, socket);
          }
          setLoadingMsg(false);
        }
      }
      fetchlist();
    } else if (
      user?._id &&
      particularStudent?.studentid !== undefined &&
      selectedUser?.group === true
    ) {
      async function fetchlist() {
        try {
          let device = Cookies.get("device");

          socket.emit("chatwith", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent?.studentid,
            // code: batchCode,
            device: "web",
            deviceId: device,

            // deviceId
          });
          let x = await AxiosInstance.get(
            `/employee/users_groupchat?group_id=${particularStudent?.studentid}`,
            {
              headers: {
                Authorization: `Bearer ${user.TOKEN}`,
              },
            }
          );

          x?.data?.data?.groupchat?.reverse();

          let data = x?.data?.data?.groupchat;

          setMessage(data);
        } catch (error) {
          if (error?.response?.status === 403) {
            handleError(navigate, socket);
          }
        }
      }
      fetchlist();
    }
  }, [particularStudent?.studentid, groupInfoUpdate]);

  let HandleBranchUserList = () => {
    setBranchUserClicked(!branchUserClicked);

    async function fetchlist() {
      //(searchapi);
      await AxiosInstance.get(`/employee/users_list`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((data) => {
          let list = data?.data?.UsersList;
          let finaldata = list?.map((ele, ind) => {
            return {
              id: ele._id,
              username: ele.username,
              role: ele.role1,
              number: ele.number.map((x) => x),
              branch: ele.branch,
              group: false,
            };
          });

          setBranchUserList(finaldata);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  };

  useEffect(() => {
    socket.on("user_notification", (data) => {
      setNotification(data);
    });
  }, [notification]);
  useEffect(() => {
    let data = convo.map((x) => {
      return x.id === notification?.msg?.sender ||
        x.id === notification?.msg?.groupId
        ? {
            ...x,
            notification: Number(x?.notification + 1),
            groupNotification: Number(x?.groupNotification + 1),
            messagetext: notification?.msg?.messages?.text
              ? notification?.msg?.messages?.text
              : "",
            messagefiles: notification?.msg?.messages?.files
              ? notification?.msg?.messages?.files
              : "",
            createdAt: notification?.msg?.createdAt,
          }
        : { ...x };
    });

    setConvo(data);
  }, [notification]);

  useEffect(() => {
    let data = convo?.map((x) => {
      return x.id === message?.slice(-1)[0]?.receiver ||
        x.id === message?.slice(-1)[0]?.groupId
        ? {
            ...x,
            messagetext: message?.slice(-1)[0].messages?.text
              ? message?.slice(-1)[0].messages?.text
              : "",
            messagefiles: message?.slice(-1)[0].messages?.files
              ? message?.slice(-1)[0].messages?.files
              : "",
            createdAt: message?.slice(-1)[0]?.createdAt,
          }
        : { ...x };
    });

    setConvo(data);
  }, [message]);

  useEffect(() => {
    socket.on("getMessage", (data) => {
      console.log(data);
      if (data) {
        console.log("123");
        setArrivalMessage(data);
      }
      // setMessage(prev => [...prev, data]);
    });
    socket.on("online_offline", (data) => {
      setOnline(data);
    });
  }, [socket]);

  useEffect(() => {
    console.log("llllllllllllllllll");
    if (arrivalMessage.length > 0) {
      setArrivalMessage([]);
    }
    setMessage((prev) => [...prev, arrivalMessage]);
  }, [particularStudent?.studentid && arrivalMessage]);
  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      // setMessage(prevMessages =>
      //   prevMessages.concat(arrivalMessageFile.length > 0)
      // );
      setArrivalMessageFile([]);
    }

    setMessage((prevMessages) => prevMessages.concat(arrivalMessageFile));
    // setArrivalMessageFile([]);
  }, [particularStudent?.studentid && arrivalMessageFile]);

  let handelSubmit = async (e) => {
    e?.preventDefault();

    if (newMessage.trim() !== "" && selectedUser?.group === false) {
      setLoading(true);

      if (reply) {
        socket?.emit(
          "users_user_chat",
          {
            senderId: user._id,
            receiverId: particularStudent?.studentid,
            text: newMessage,
            role: user?.role1,
            index: 0,
            //  code: batchName,

            device: "web",
            status: null,
            replayback: {
              messages: selectedMessages[0]?.messages?.text
                ? selectedMessages[0]?.messages?.text
                : "",
              file: selectedMessages[0]?.messages?.files
                ? selectedMessages[0]?.messages?.files
                : "",
            },
          },
          function callback(msg) {
            setReply(false);
            setSelectedMessages([]);
            let data = convo.map((x) => {
              return x?.id?.includes(msg?.receiver);
            });
            setLoading(false);

            const allFalse = data.every((result) => result === false);

            if (allFalse) {
              setUserListClicked(true);
            } else {
            }
            if (msg.read === false) {
              msg.tick = false;
              setMessage([...message, msg]);
            } else {
              setMessage([...message, msg]);
            }
          }
        );
      } else {
        socket?.emit(
          "users_user_chat",
          {
            senderId: user._id,
            receiverId: particularStudent?.studentid,
            text: newMessage,
            role: user?.role1,
            index: 0,
            //  code: batchName,

            device: "web",
            status: null,
          },
          function callback(msg) {
            let data = convo.map((x) => {
              return x?.id?.includes(msg?.receiver);
            });
            setLoading(false);

            const allFalse = data.every((result) => result === false);

            if (allFalse) {
              setUserListClicked(true);
            } else {
            }
            if (msg.read === false) {
              msg.tick = false;
              setMessage([...message, msg]);
            } else {
              setMessage([...message, msg]);
            }
          }
        );
      }
    } else if (newMessage.trim() !== "" && selectedUser?.group === true) {
      setLoading(true);
      if (reply) {
        socket?.emit(
          "users_group",
          {
            senderId: user?._id,
            sendername: user?.username,
            groupId: selectedUser?.id,
            groupname: selectedUser?.username,
            text: newMessage,
            role: user?.role1,
            index: 0,
            device: "web",
            status: null,
            replayback: {
              message: selectedMessages[0]?.messages?.text
                ? selectedMessages[0]?.messages?.text
                : "",
              file: selectedMessages[0]?.messages?.files
                ? selectedMessages[0]?.messages?.files
                : "",
            },
          },
          function callback(msg) {
            setReply(false);
            setSelectedMessages([]);
            // //(msg);
            if (msg.read === false) {
              msg.tick = false;
              setMessage([...message, msg]);
            } else {
              setMessage([...message, msg]);
            }
            setLoading(false);
          }
        );
      } else {
        socket?.emit(
          "users_group",
          {
            senderId: user?._id,
            sendername: user?.username,
            groupId: selectedUser?.id,
            groupname: selectedUser?.username,
            text: newMessage,
            role: user?.role1,
            index: 0,

            //  code: batchName,

            device: "web",
            status: null,
          },
          function callback(msg) {
            // //(msg);
            if (msg.read === false) {
              msg.tick = false;
              setMessage([...message, msg]);
            } else {
              setMessage([...message, msg]);
            }
            setLoading(false);
          }
        );
      }
    }
    try {
      setNewMessage("");
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
      //(error);
    }
  };

  let handleNotification = (sid) => {
    let data1 = convo?.map((x) => {
      if (x.notification > 0 && x.group === false) {
        // setNotificationCount(x.notification);
        if (x.notification > 0) {
          socket.emit("usersmsgread", {
            group: false,
            senderId: user?._id,
            receiverId: sid,
            device: "web",
          });
        }
      } else if (x.groupNotification > 0 && x.group === true) {
        // setNotificationCount(x.groupNotification);

        if (x.groupNotification > 0) {
          socket.emit("usersmsgread", {
            group: true,
            senderId: user?._id,
            groupid: sid,
            device: "web",
          });
        }
      }

      return x.id === sid
        ? x.notification > 0
          ? {
              ...x,
              notification: (x.notification = 0),
            }
          : x.groupNotification > 0
          ? { ...x, groupNotification: (x.groupNotification = 0) }
          : { ...x }
        : x;
    });

    setConvo(data1);
  };

  let handelSubmitButton = (e) => {
    handelSubmit();
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };

  let selectedmsg = (data) => {
    let aaa = forwardmsg?.map((x) => x.index);
    const index = data.index;
    if (aaa.includes(index)) {
      for (let i = 0; i < forwardmsg.length; i++) {
        if (forwardmsg[i].index === index) {
          forwardmsg.splice(i, 1);
          i--; // decrement i to account for removed element
        }
      }
    } else {
      if (forwardmsg.length == 5 || forwardmsg.length > 5) {
        alert("you can only send 5 messages at a time");
      } else {
        forwardmsg.push(data);
      }
    }
  };
  let CancelForward = () => {
    setForward(false);
    setSelectedMessages([]);
  };
  let CancelReply = () => {
    setReply(false);
    setForward(false);
    setSelectedMessages([]);
  };
  let handleSearch = (e) => {
    setSearch(e.target.value);
  };
  let HandleClickSearch = () => {
    HandleSearch(search);
    setSearchTrue(true);
  };
  let HandleClearSearch = () => {
    // HandleClear();
    setSearchApi("");
    setSearch("");
    setClear(true);
    setSearchTrue(false);
  };

  const handleKeyPressUser = (e) => {
    if (e.key === "Enter") {
      if (search.trim() !== "") {
        // Trigger search if input is not empty
        HandleSearch(search);
        setSearchTrue(true);
      }
    }
  };
  const handleBackspaceUser = (event) => {
    if (event.key === "Backspace" && search.length === 1) {
      setSearchApi("");
      setSearch("");
      setClear(true);
      setSearchTrue(false);
    }
  };
  let HandleUpdateName = () => {
    let payload = {
      groupName: groupName,
      group_id: selectedUser?.id,
      id: user._id,
      type: "gnc",
    };
    async function students() {
      await AxiosInstance.post(`/employee/users_groupupdate`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          handleCloseEdit();

          fetchGroupInfo();

          setGroupNameTrue(true);
          setGroupInfoUpdate(true);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };

  let HandleUpdateUsers = () => {
    let payload = {
      group_id: selectedUser?.id,
      usersList: GroupUsers,
      id: user?._id,
      type: "anu",
    };

    async function students() {
      await AxiosInstance.post(`/employee/users_groupupdate`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          handleCloseEdit();
          fetchGroupInfo();
          setGroupInfoUpdate(true);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {
          setOpenUpdate(false);
        });
    }
    students();
  };
  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

    setNewMessage(pastedText);
  };
  let HandleExit = () => {
    let payload = {
      group_id: selectedUser?.id,
      usersList: [{ _id: user._id, username: user.username }],
      id: user?._id,
      type: "ru",
    };

    async function students() {
      await AxiosInstance.post(`/employee/users_groupupdate`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          handleCloseEdit();
          setGroupNameTrue(true);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {
          setSidebarOpen(false);
          setParticularStudent({});
        });
    }
    students();
  };

  let handleChangeGroup = (e) => {
    // //(e);
    setGroupUsers(
      Array.isArray(e)
        ? e.map((x) => {
            return { _id: x.value.id, username: x.value.username };
          })
        : []
    );
  };
  const handleMessageClick = (clickedMessage, index, isMessageSelected) => {
    const existingIndex = selectedMessages.findIndex(
      (msg) =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };
  return (
    <section>
      <div className={Styles.app}>
        <div className={Styles.sidebar}>
          <header className={Styles.header}>
            {/* <!-- WhatsApp Logo --> */}
            <span
              className={Styles.UserList + " " + Styles.conversationIconHolder}
              onClick={() => {
                HandleBranchUserList();
                setStop(false);
                setIsPlaying(false);
                setRecordedAudio(null);
                setIsPlaybackActive(false);
                setIsAudioVisible(false); // Hide the audio tag
                setRecordedChunks([]); // Clear the recorded audio chunks
                setIsPlaying(false);
                setSidebarOpen(false);
                setSearchUser("");
              }}>
              <BsChatSquareTextFill />
            </span>
            <Link to={`/user-dashboard/userConversation/CreateGroupUser`}>
              {" "}
              <span
                className={
                  Styles.UserList + " " + Styles.conversationIconHolder
                }
                //   onClick={() => {
                //     HandleBranchUserList();
                //   }}
              >
                <FaUsers />
              </span>
            </Link>
          </header>
          {branchUserClicked === true ? (
            <div className={Styles.search}>
              {/* <!-- Search Bar --> */}
              <div className={Styles.SearchBlock}>
                <span className={Styles.SearchText}>
                  <input
                    name="searchUser"
                    id="searchUser"
                    type="text"
                    //   value={search}
                    placeholder="search User"
                    onChange={(e) => setSearchUser(e.target.value)}
                  />
                </span>
              </div>
            </div>
          ) : (
            <div className={Styles.search}>
              <div className={Styles.SearchBlock}>
                <span className={Styles.SearchText}>
                  <input
                    id="search"
                    name="search"
                    type="text"
                    value={search}
                    placeholder="Search Users"
                    onChange={(e) => handleSearch(e)}
                    onKeyPress={handleKeyPressUser}
                    onKeyDown={handleBackspaceUser}
                  />
                </span>
                {searchTrue === false ? (
                  <button
                    className={Styles.searchIcon}
                    onClick={HandleClickSearch}>
                    <FiSearch />
                  </button>
                ) : (
                  <button
                    className={Styles.searchCancel}
                    onClick={HandleClearSearch}>
                    <ImCross />
                  </button>
                )}
              </div>
            </div>
          )}
          <div className={Styles.contacts}>
            {/* <!-- List of Contacts --> */}
            {branchUserClicked === true
              ? branchUserList?.length > 0 &&
                branchUserList
                  ?.filter((students) =>
                    students?.username
                      ?.toLowerCase()
                      ?.includes(searchUser.toLowerCase())
                  )
                  .map((ele, ind) => {
                    return (
                      <section key={ind}>
                        <div
                          className={Styles.message}
                          onClick={() => {
                            setParticularStudent({ studentid: ele.id });
                            setSelectedUser(ele);
                            setBranchUserClicked(false);
                            setMessage([]);
                            // handleNotification(ele._id);
                          }}
                          style={{
                            backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlXLm169P0IIPVSlSTI2gNkqihXI2jNcHMbfaJ_xlkm6L1leFed3LhgkUYfAw0PCrSDRY&usqp=CAU')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            border: "0.3px ridge #999",
                          }}>
                          <div
                            className={Styles.senderinfo}
                            style={{ color: "#111" }}>
                            {/* <div className={Styles.sendername}> */}
                            {ele.username}
                            {/* </div> */}
                          </div>
                        </div>
                      </section>
                    );
                  })
              : convo.length > 0 &&
                convo
                  .sort(
                    (a, b) =>
                      new Date(b?.createdAt).getTime() -
                      new Date(a?.createdAt).getTime()
                  )
                  .map((ele, ind) => {
                    return (
                      <div
                        key={ind}
                        className={Styles.message}
                        onClick={() => {
                          setParticularStudent({ studentid: ele.id });
                          setSelectedUser(ele);
                          handleNotification(ele.id);
                          setStop(false);
                          setIsPlaying(false);
                          setRecordedAudio(null);
                          setIsPlaybackActive(false);
                          setIsAudioVisible(false);
                          setRecordedChunks([]);
                          setIsPlaying(false);
                          setIsRecording(false);
                          setSidebarOpen(false);
                        }}
                        style={{
                          backgroundImage:
                            ele.group === true
                              ? `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`
                              : `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlXLm169P0IIPVSlSTI2gNkqihXI2jNcHMbfaJ_xlkm6L1leFed3LhgkUYfAw0PCrSDRY&usqp=CAU')`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          border:
                            selectedUser?.id === ele.id
                              ? "2px solid #f16f1d"
                              : "0.3px ridge #999",
                        }}>
                        <div className={Styles.senderinfo}>
                          <div className={Styles.sendername}>
                            {ele.username}
                            {user?.role.includes("delivery-team") ? (
                              <small
                                style={{ color: "gray", fontSize: "xx-small" }}>
                                ({ele?.role})
                              </small>
                            ) : null}
                            {ele.group === true && (
                              <span className={Styles.GroupIcon}>
                                <BsFillPeopleFill />
                              </span>
                            )}
                          </div>
                          {ele.group === false ? (
                            ele.notification > 0 ? (
                              <div className={Styles.timestamp}>
                                {ele.notification}
                              </div>
                            ) : (
                              ""
                            )
                          ) : ele.groupNotification > 0 ? (
                            <div className={Styles.timestamp}>
                              {ele.groupNotification}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className={Styles.tickicon}></div>
                        </div>
                        <div className={Styles.messagetext}>
                          {ele.messagefiles
                            ? `${ele?.messagefiles[0]?.originalname.slice(
                                0,
                                15
                              )}(File)`
                            : ele?.messagetext?.length > 20
                            ? ele?.messagetext?.slice(0, 20) + " " + ". . ."
                            : ele?.messagetext}
                          <span className={Styles.time}>
                            {ele?.createdAt
                              ? dateFormat(ele?.createdAt, "mmm d, yyyy") ===
                                today
                                ? dateFormat(ele?.createdAt, "DDDD h:MM TT")
                                : dateFormat(ele?.createdAt, "DDDD h:MM TT")
                              : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })}
          </div>
        </div>

        {particularStudent?.studentid ? (
          <div className={Styles.chatarea}>
            <div className={Styles.NameBlock}>
              {selectedUser?.group === false ? (
                <div className={Styles.HEadBlockName}>
                  {" "}
                  <span className={Styles.HeadName}>
                    <h6>{selectedUser?.username}</h6>
                  </span>
                  <span className={Styles.HeadDeatils}>
                    <h6> Chats Are Monitored By Admin</h6>
                  </span>
                </div>
              ) : (
                <div className={Styles.HEadBlockName}>
                  <span onClick={toggleSidebar}>
                    <h6 style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <BsFillPeopleFill
                        color="#0a665d"
                        style={{ fontSize: "large" }}
                      />{" "}
                      &nbsp;
                      {selectedUser?.username}
                    </h6>
                  </span>
                  <span className={Styles.HeadDeatils}>
                    <h6> Chats Are Monitored By Admin</h6>
                  </span>
                </div>
              )}

              {selectedUser?.group === false ? (
                <span>
                  {online === "online" ? (
                    <span className={Styles.Online}>Online</span>
                  ) : (
                    <span className={Styles.Offline}>Offline</span>
                  )}
                </span>
              ) : (
                ""
              )}
            </div>
            <aside
              className={Styles.ChatBlock}
              style={{
                backgroundImage: `url('https://img.freepik.com/free-photo/creative-wallpaper-with-white-shapes_23-2148811498.jpg?size=626&ext=jpg&ga=GA1.1.1318602343.1709182378&semt=ais')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}>
              <div className={Styles.chat}>
                {/* <!-- Messages --> */}
                {loadingmsg === true ? (
                  <div className={Styles.loader}>
                    {" "}
                    <ThreeDots color="#00BFFF" height={80} width={80} />
                  </div>
                ) : message?.length > 0 ? (
                  message?.map((ele, ind) => (
                    <Fragment key={ind}>
                      <UserMessage
                        index={ind}
                        messages={ele}
                        own={ele?.sender === user?._id}
                        particularStudent={particularStudent}
                        loadingmsg={loadingmsg}
                        selectedmsg={selectedmsg}
                        forwardmsg={forwardmsg}
                        setSelected={setSelected}
                        selected={selected}
                        setReply={setReply}
                        reply={reply}
                        setForward={setForward}
                        forward={forward}
                        onClick={handleMessageClick}
                        selectedMessages={selectedMessages}
                        setSelectedMessages={setSelectedMessages}
                      />
                    </Fragment>
                  ))
                ) : (
                  <span className={Styles.noConversation}>
                    <p style={{ color: "#f16f1d" }}>No Messages</p>
                  </span>
                )}
              </div>
              <div ref={scrollRef} style={{ visibility: "hidden" }}>
                top
              </div>
            </aside>

            {!forward ? (
              <form onSubmit={handelSubmit} className={Styles.FormMessage}>
                {reply && (
                  <div className={Styles.replyBlock}>
                    <div className={Styles.StatusText1}>
                      {" "}
                      {selectedMessages && (
                        <>
                          <div className={Styles.copiedText}>
                            {selectedMessages[0]?.messages?.text
                              ? selectedMessages[0]?.messages?.text?.slice(
                                  0,
                                  180
                                )
                              : selectedMessages[0]?.messages?.files[0]
                                  ?.filename}
                          </div>
                          <div
                            className={Styles.CrossMark}
                            onClick={() => CancelReply()}>
                            X
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className={Styles.messageinput}>
                  {/* <!-- Message Input Field --> */}
                  <input
                    type="text"
                    placeholder="Type your message"
                    value={newMessage}
                    onChange={(e) => {
                      setNewMessage(e.target.value);
                    }}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    autoFocus
                  />
                  <aside
                    style={{ position: "relative" }}
                    className={Styles.UploadfileContainer}>
                    <>
                      {isRecording ? (
                        ""
                      ) : stop ? (
                        ""
                      ) : (
                        <>
                          <ButtonToolbar ref={iconRef}>
                            <FaPlus onClick={toggleOptions} />
                          </ButtonToolbar>
                          {areOptionsVisible && (
                            <div className={Styles.ropdowncontent}>
                              {/* Add your dropdown content here */}
                              <ul>
                                <li
                                  className={Styles.FileShare}
                                  onClick={handleOpen}>
                                  <ImAttachment></ImAttachment>
                                  files
                                </li>
                                <li
                                  className={Styles.FileShare}
                                  onClick={handleOpenPoll}>
                                  <BsFillBarChartFill></BsFillBarChartFill> Poll
                                </li>
                              </ul>
                            </div>
                          )}
                          <Modal
                            // backdrop={backdrop}
                            keyboard={false}
                            open={openPoll}
                            onClose={handleClosePoll}
                            backdrop="static">
                            {/* <Modal.Header> */}
                            {/* <Modal.Title> */}
                            <div className={Styles.HeaderPoll}>
                              <span onClick={handleClosePoll}>
                                <ImCross />
                              </span>
                              <h4>Create Poll</h4>
                            </div>
                            {/* </Modal.Title> */}
                            {/* </Modal.Header> */}

                            <Modal.Body>
                              {poll?.map((qa, index) => (
                                <div
                                  className={Styles.modalcontent}
                                  key={index}>
                                  <div>
                                    <div className={Styles.QuestionBlock}>
                                      <h5>Question:</h5>

                                      <span
                                        className={Styles.deleteicon}
                                        onClick={() => deleteDiv(index)}>
                                        <FaMinus />
                                      </span>
                                    </div>
                                    <input
                                      className={Styles.InputQue}
                                      type="text"
                                      id="question"
                                      placeholder="Add question"
                                      value={qa?.question}
                                      onChange={(e) => {
                                        handleQuestionChange(
                                          index,
                                          e.target.value
                                        );
                                        const updatedErrors = {
                                          ...errors,
                                        };
                                        delete updatedErrors[
                                          `question_${index}`
                                        ];
                                        setErrors(updatedErrors);
                                      }}
                                    />
                                    {errors[`question_${index}`] && (
                                      <p className={Styles.errormessage}>
                                        {errors[`question_${index}`]}
                                      </p>
                                    )}
                                  </div>
                                  <h5>Options:</h5>
                                  {qa?.options?.map((option, ind) => (
                                    <div
                                      key={ind}
                                      className={Styles.OptionBlock}>
                                      <div className={Styles.InputOption}>
                                        {" "}
                                        <input
                                          className={Styles.InputQue}
                                          type="text"
                                          placeholder="Add"
                                          value={option}
                                          onChange={(e) => {
                                            handleOptionChange(
                                              index,
                                              ind,
                                              e.target.value
                                            );
                                            const updatedErrors = {
                                              ...errors,
                                            };
                                            delete updatedErrors[
                                              `options_${index}`
                                            ];
                                            setErrors(updatedErrors);
                                          }}
                                        />
                                        <button
                                          className={Styles.ClearOption}
                                          onClick={() =>
                                            handleClearOption(index, ind)
                                          }>
                                          <ImCross />
                                        </button>
                                      </div>
                                      {errors[`options_${index}`] && (
                                        <p className={Styles.errormessage}>
                                          {errors[`options_${index}`]}
                                        </p>
                                      )}
                                    </div>
                                  ))}
                                  <button
                                    onClick={() => handleAddOption(index)}
                                    className={Styles.AddOptions}>
                                    <FaPlus />
                                  </button>
                                  <div className={Styles.datePicker}>
                                    {" "}
                                    <DatePicker
                                      format="yyyy-MM-dd HH:mm"
                                      calendarDefaultDate={new Date()}
                                      disabledDate={disablePastDate}
                                      onChange={(date) => {
                                        handleDateChange(index, date);
                                        const updatedErrors = {
                                          ...errors,
                                        };
                                        delete updatedErrors[`date_${index}`];
                                        setErrors(updatedErrors);
                                      }}
                                      ranges={[
                                        {
                                          label: "Now",
                                          value: new Date(),
                                        },
                                      ]}
                                      style={{ width: 260 }}
                                    />
                                    {errors[`date_${index}`] && (
                                      <p className={Styles.errormessage}>
                                        {errors[`date_${index}`]}
                                      </p>
                                    )}
                                  </div>
                                  <div className={Styles.Allow}>
                                    <h6>Allow multiple answers</h6>{" "}
                                    <span>
                                      {" "}
                                      <Toggle
                                        checked={qa?.toggleValue}
                                        onChange={() =>
                                          handleToggleChange(index)
                                        }
                                      />
                                    </span>
                                  </div>
                                  <div className={Styles.Allow}>
                                    <h6>Allow to View votes</h6>{" "}
                                    <span>
                                      {" "}
                                      <Toggle
                                        checked={qa.toggleValue1}
                                        onChange={() =>
                                          handleToggleChange1(index)
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              ))}
                              <button
                                onClick={addDiv}
                                className={Styles.AddPoll}>
                                <BiPlusMedical />
                              </button>
                            </Modal.Body>
                            <Modal.Footer>
                              {" "}
                              <button
                                onClick={handleSubmit}
                                className={Styles.SubmitPoll}>
                                <IoSendSharp />
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      )}

                      <Modal
                        // backdrop={backdrop}
                        keyboard={false}
                        open={open}
                        onClose={handleClose}>
                        <Modal.Header>
                          <Modal.Title>Send File</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <FileUpload
                            PStudent={particularStudent}
                            setMessages={setMessage}
                            messages={message}
                            setOpen={setOpen}
                            selectedUser={selectedUser}
                            // batchName={batchName}
                            setArrivalMessage={setArrivalMessage}
                            setArrivalMessageFile={setArrivalMessageFile}
                            reply={reply}
                            selectedMessages={selectedMessages}
                            setReply={setReply}
                            setSelectedMessages={setSelectedMessages}
                          />
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>
                    </>
                  </aside>
                  <aside className={Styles.AudioBlock}>
                    <div>
                      {!stop && !isRecording && !isPlaying && (
                        <Whisper
                          followCursor
                          speaker={<Tooltip>{"Start"}</Tooltip>}>
                          <main
                            className={Styles.StartAudio}
                            onClick={handleStartRecording}
                            disabled={isRecording}>
                            <span>
                              {/* start */}
                              <BsFillMicFill />
                            </span>
                          </main>
                        </Whisper>
                      )}
                      <div
                        className={`${Styles.recordingAnimation} ${
                          isRecording ? Styles.active : ""
                        }`}></div>
                      {isRecording && (
                        <main
                          className={Styles.StopAudio}
                          onClick={handleStopRecording}
                          disabled={!isRecording}>
                          <span>
                            {/* stop */}
                            <BsFillMicMuteFill />
                          </span>
                        </main>
                      )}
                      {stop && (
                        <Whisper
                          followCursor
                          speaker={<Tooltip>{"Play"}</Tooltip>}>
                          <main
                            className={Styles.PlayAudio}
                            onClick={handlePlayButtonClick}
                            disabled={isRecording}>
                            <span>
                              {/* play */}
                              <BsFillPlayCircleFill />
                            </span>
                          </main>
                        </Whisper>
                      )}
                      {/* {stop &&<p>{recordingStatus}</p>} */}
                      {audioPlayer}
                    </div>
                  </aside>
                  {stop && (
                    <div className={Styles.SendAudioBlock}>
                      <Whisper
                        followCursor
                        speaker={<Tooltip>{"Delete"}</Tooltip>}>
                        <span
                          onClick={handleDeleteButtonClick}
                          className={Styles.DeleteAudio}>
                          <AiFillDelete />
                        </span>
                      </Whisper>
                      <span onClick={HandleAudio} className={Styles.SendAudio}>
                        send
                        {/* <AiOutlineSend /> */}
                      </span>
                    </div>
                  )}
                  {!stop && (
                    <div>
                      {loading || isRecording ? (
                        <button
                          className="chatSubmitButton"
                          disabled
                          onClick={handelSubmitButton}>
                          <div className={Styles.loader}>
                            <RotatingLines
                              strokeColor="#fff"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="20"
                              visible={true}
                            />
                          </div>
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            className={Styles.sendbutton}
                            onClick={handelSubmitButton}>
                            Send
                          </button>
                          <button
                            type="submit"
                            style={{ display: "none" }}
                            className={Styles.sendbutton}
                            onClick={handelSubmitButton}>
                            Send
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </form>
            ) : (
              <div className={Styles.ForwardPanel}>
                {" "}
                <>
                  <div>
                    {" "}
                    <div>Forward Message</div>
                  </div>
                  <div
                    className={Styles.CrossMarkCancel}
                    onClick={() => CancelForward()}>
                    X
                  </div>
                </>
              </div>
            )}
          </div>
        ) : (
          <span
            className={Styles.noConversationText}
            style={{
              backgroundImage: `url('https://img.freepik.com/premium-vector/abstract-technology-white-gray-color-modern-background-design-white-geometric-texture-vector-illustration_41981-1768.jpg?size=626&ext=jpg&ga=GA1.1.1318602343.1709182378&semt=ais')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <p style={{ color: "#f16f1d" }}>
              Open a conversation to start a chat.
            </p>
            <div>
              {" "}
              <span>
                {" "}
                <BsChatDots />
              </span>
              Chats Are Monitored By Admin
            </div>
            {/* <p>These Chats Are Monitored By Admin</p> */}
          </span>
        )}
        <Modal
          // backdrop={backdrop}
          keyboard={false}
          open={openEdit}
          onClose={handleCloseEdit}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Modal.Header>
            <Modal.Title>Edit Group Name</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              className={Styles.InputGroup}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" onClick={HandleUpdateName}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          // backdrop={backdrop}
          keyboard={false}
          open={openUpdate}
          size="lg"
          onClose={handleCloseUpdate}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Modal.Header>
            <Modal.Title>Add Users To Group</Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{
              overflow: "unset",
              maxheight: "none",
            }}>
            <Select
              closeMenuOnSelect={false}
              isMulti
              // inputValue={searchQuery}
              // onInputChange={handleInputChange}
              name="NonAddedUser"
              options={NonAddedUser}
              onChange={handleChangeGroup}
              onMenuScrollToBottom
            />
          </Modal.Body>
          <Modal.Footer>
            <Button appearance="primary" onClick={HandleUpdateUsers}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        {selectedUser?.group === true && (
          <div
            className={`${Styles.sidebar1} ${sidebarOpen ? Styles.open : ""}`}>
            <div className={Styles.HearderSidebar}>
              <span
                className={Styles.ImCrossMark}
                onClick={() => setSidebarOpen(false)}>
                <ImCross />
              </span>
              <h6>Group info</h6>
            </div>
            <div className={Styles.sidebarContent}>
              {/* Content for the sidebar */}

              <div className={Styles.PrfileIcon}>
                <FaUserCircle />
              </div>
              <div className={Styles.EDitPrfile}>
                <div className={Styles.DisplayFlex}>
                  <h6>{groupinfo?.groupName} </h6>
                  <div className={Styles.IconsEditAdd}>
                    {" "}
                    <span className={Styles.EditIcon} onClick={handleOpenEdit}>
                      <MdModeEditOutline />
                    </span>
                    <span
                      className={Styles.EditIcon}
                      onClick={handleOpenUpdate}>
                      <BsPlusCircleFill />
                    </span>
                  </div>
                </div>
                <div className={Styles.TextGray}>
                  Group - {groupinfo?.usersList?.length}participants
                </div>
              </div>
            </div>
            <div className={Styles.ListOfUsers}>
              <h6 className={Styles.Head}>participants</h6>

              {groupinfo &&
                groupinfo?.usersList?.length > 0 &&
                groupinfo?.usersList?.map((x, i) => {
                  // //(x);
                  return groupinfo?.author?.id !== user._id &&
                    groupinfo?.usertype === "user" ? (
                    <section key={i}>
                      <div className={Styles.ListParticipates}>
                        <div className={Styles.iconAndName}>
                          {" "}
                          <span className={Styles.IconProfile}>
                            {" "}
                            <FaUserCircle />
                          </span>
                          <span> {x?.username}</span>
                        </div>
                        {x?.type === "admin" && (
                          <span className={Styles.Admin}>{x.type}</span>
                        )}
                      </div>
                      <div
                        className={Styles.AdminROle}
                        onClick={() => setAdminUser(x)}>
                        {/* {x?.type === "admin" && x?.mongoid === user._id ? (
                          <Dropdown title="" placement="bottomEnd"></Dropdown>
                        ) : x?.type === "admin" ? (
                          <Dropdown title="." placement="bottomEnd">
                            {items1}
                          </Dropdown>
                        ) : (
                          <Dropdown title="." placement="bottomEnd">
                            {items}
                          </Dropdown>
                        )} */}
                      </div>
                    </section>
                  ) : (
                    <>
                      <div className={Styles.ListParticipates}>
                        <div className={Styles.iconAndName}>
                          {" "}
                          <span className={Styles.IconProfile}>
                            {" "}
                            <FaUserCircle />
                          </span>
                          <span> {x?.username}</span>
                        </div>
                        {x?.type === "admin" && (
                          <span className={Styles.Admin}>{x.type}</span>
                        )}
                      </div>
                      <div
                        className={Styles.AdminROle}
                        onClick={() => setAdminUser(x)}>
                        {x?.type === "admin" && x?.mongoid === user._id ? (
                          <Dropdown title="" placement="bottomEnd"></Dropdown>
                        ) : x?.type === "admin" ? (
                          <Dropdown title="." placement="bottomEnd">
                            {items1}
                          </Dropdown>
                        ) : (
                          <Dropdown title="." placement="bottomEnd">
                            {items}
                          </Dropdown>
                        )}
                      </div>
                    </>
                  );
                })}
            </div>
            <div className={Styles.LeaveGroup}>
              <aside onClick={HandleExit}>
                Exit Group{" "}
                <span>
                  <BiExit />
                </span>
              </aside>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default UserList;
