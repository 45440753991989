import React, { useContext, useEffect, useState } from "react";
import Styles from "./_chat.module.css";
import { SocketContext } from "../../../ioContext/socketIo";
import dateFormat from "dateformat";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";

import { Tooltip, Whisper } from "rsuite";
import linkifyHtml from "linkify-html";
const StuConversation = ({
  batchTrainer,
  setParticularStudent,
  setRecentChatUsersList,
  batchname,
  groupname,
  messages,
  setStop,
  setIsPlaying,
  setRecordedAudio,
  setIsPlaybackActive,
  setIsAudioVisible,
  setRecordedChunks,
  setActiveSection,
  setMessages,
  particularStudent,
  setSelectedMessages,
  setReply,
  handleBackClick,
  setMsgScroll,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [search, setSearch] = useState("");
  const socket = useContext(SocketContext);
  let [notification, setNotification] = useState({});
  let [batchStudentList, setBatchStudentList] = useState([]);
  // batchStudentList = batchTrainer;
  useEffect(() => {
    socket.on("ischatroom", (data) => {
      // console.log(data);
    });
    socket.on("notification_new", (data) => {
      setNotification(data);
    });
  }, [notification]);

  useEffect(() => {
    let data1 = batchTrainer?.map((y) => {
      return y?._id === notification?.msg?.from
        ? {
            ...y,
            createdAt: notification?.msg?.created_at,
            lastmsg: notification?.msg,
            studentNotification: Number(y?.studentNotification) + 1,
          }
        : { ...y };
    });
    console.log(data1[0]);
    setRecentChatUsersList(data1);
  }, [notification]);
  useEffect(() => {
    if (!messages?.length) return; // Ensure messages is non-empty

    const lastMessage = messages[messages.length - 1]; // Get the last message
    const updatedList = batchTrainer?.map((x) => {
      if (x?._id === lastMessage?.to) {
        return {
          ...x,
          createdAt: lastMessage.created_at,
          lastmsg: lastMessage,
        };
      }
      return x; // Return the item as is if the condition doesn't match
    });

    setRecentChatUsersList(updatedList); // Update the state
  }, [messages]); // Add batchStudentList as a dependency

  let handleNotification = (sid) => {
    let data1 = batchTrainer?.map((x) => {
      return x._id === sid
        ? {
            ...x,
            lastmsg: {
              ...x.lastmsg,
            },

            studentNotification: 0,
          }
        : x;
    });
    setRecentChatUsersList(data1);
  };

  let d = Date.now();
  let today = dateFormat(d, "mmm d, yyyy");
  //(search);
  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    console.log({ text });
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };
    let convertText = linkifyHtml(text, linkifyOptions);
    return convertText;
  }
  return (
    <>
      <div className={Styles.Head}>
        <h1>{user.username}</h1>
        {batchname ? <h4>{batchname}</h4> : <h4>{groupname}</h4>}
      </div>

      <div>
        <div className={Styles.chatMenuWrapper}>
          <span>
            <input
              name="search"
              type="search"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value?.toLowerCase())}
            />
          </span>
          <span className={Styles.searchIcon}></span>
          <div className={Styles.ConversationBlock}>
            <>
              {batchTrainer.length == 0 ? (
                <p style={{ textAlign: "center" }}>No Data Found</p>
              ) : (
                batchTrainer?.length > 0 &&
                batchTrainer
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .filter((trainer) =>
                    trainer?.username?.toLowerCase()?.includes(search)
                  )
                  .map((trainer, ind) => {
                    return (
                      <div
                        style={{
                          backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          border: "0.3px ridge #999",
                          borderRadius: "6px",
                          marginBottom: "5px",
                        }}
                        className={Styles.listUser}
                        key={ind}
                        onClick={() => {
                          setParticularStudent({
                            role: user?.role1,
                            code: batchname ? batchname : groupname,
                            trainersId: trainer?._id,
                            trainerName: trainer?.username,
                            notification:
                              trainer?.studentNotification > 0 ? true : false,
                            notiCount: trainer?.usersNotification,
                            studentnoti: trainer?.studentNotification,
                            userleft: trainer?.userleft,
                          });
                          setMsgScroll(0);
                          handleNotification(trainer?._id);
                          setStop(false);
                          setIsPlaying(false);
                          setRecordedAudio(null);
                          setIsPlaybackActive(false);
                          setSelectedMessages([]);
                          setReply(false);

                          setIsAudioVisible(false); // Hide the audio tag
                          setRecordedChunks([]); // Clear the recorded audio chunks
                          setIsPlaying(false);
                          handleBackClick();
                          setActiveSection("");

                          if (
                            particularStudent.trainersId !==
                            particularStudent.trainersId
                          ) {
                            setMessages([]);
                          }
                        }}>
                        <div className={Styles.conversation}>
                          {trainer?.role?.length > 1 ? (
                            <Whisper
                              followCursor
                              speaker={
                                <Tooltip>{trainer?.role.join()}</Tooltip>
                              }>
                              <span>
                                {trainer?.username} -{" "}
                                {trainer?.role?.length > 1
                                  ? trainer?.role
                                      .join()
                                      ?.toString()
                                      .substring(0, 15) + ". . ."
                                  : trainer?.role}
                              </span>
                            </Whisper>
                          ) : (
                            <span>
                              {trainer?.username} - {trainer?.role[0]}
                            </span>
                          )}

                          <div
                            className={
                              trainer?.studentNotification
                                ? trainer?.studentNotification !== 0
                                  ? `${Styles.notify}`
                                  : `${Styles.nonotify}`
                                : ""
                            }>
                            <span>
                              {trainer?.studentNotification !== 0
                                ? trainer?.studentNotification
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className={Styles.tickmark}>
                          <div>
                            {/* <span> */}
                            {trainer?.lastmsg ? (
                              trainer?.from !== user?._id ? (
                                ""
                              ) : trainer?.read ? (
                                <IoCheckmarkDoneSharp
                                  className={Styles.icontick}
                                  style={{ color: " rgb(67, 160, 71)" }}
                                />
                              ) : (
                                <FcCheckmark className={Styles.icontick} />
                              )
                            ) : (
                              ""
                            )}

                            <p
                              className={
                                trainer?.lastmsg
                                  ? `${Styles.lastmsg}`
                                  : `${Styles.Nolastmsg}`
                              }>
                              <span>
                                {Object.keys(trainer?.lastmsg).length > 0 ? (
                                  trainer?.lastmsg?.files ? (
                                    `${trainer?.lastmsg?.files?.originalname.slice(
                                      0,
                                      15
                                    )}(File)`
                                  ) : trainer?.lastmsg?.html ? (
                                    <p
                                      className={Styles.textEditStyle}
                                      style={{ fontSize: "11px" }}>
                                      New Message....
                                    </p>
                                  ) : trainer?.lastmsg?.text?.length > 20 ? (
                                    trainer?.lastmsg?.text?.slice(0, 20) +
                                    " " +
                                    ". . ."
                                  ) : (
                                    trainer?.lastmsg?.text
                                  )
                                ) : (
                                  <span>No recent message</span>
                                )}
                              </span>
                            </p>
                          </div>
                          <span className={Styles.time}>
                            {trainer?.lastmsg?.created_at
                              ? dateFormat(
                                  trainer?.lastmsg?.created_at,
                                  "mmm d, yyyy"
                                ) === today
                                ? dateFormat(
                                    trainer?.lastmsg?.created_at,
                                    "DDDD h:MM TT"
                                  )
                                : dateFormat(
                                    trainer?.lastmsg?.created_at,
                                    "DDDD h:MM TT"
                                  )
                              : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default StuConversation;
