import React, { useContext, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { globalContext } from "../UsersContext";
import Styles from "./_chat.module.css";
import { SiGoogleclassroom } from "react-icons/si";
import Moment from "react-moment";
import dateFormat from "dateformat";

const Conversation = ({ batchStdent }) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [search, setSearch] = useState("");
  let { switchComp, setSwitchComp } = useContext(globalContext);

  return (
    <>
      <div className={Styles.Head}>
        <h1>
          Name : <b style={{ color: "#666" }}>{user?.username}</b>
        </h1>
        {switchComp ? (
          ""
        ) : (
          <button
            onClick={() => setSwitchComp(true)}
            className={Styles.Broadcast}>
            <span>
              <SiGoogleclassroom></SiGoogleclassroom>
            </span>{" "}
            studentlist
          </button>
        )}
      </div>
      <div>
        <div className={Styles.chatMenuWrapper}>
          <span>
            <input
              type="text"
              placeholder="Search student"
              name="search"
              id="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
          <span className={Styles.searchIcon}>
            <FiSearch />
          </span>
          <div className={Styles.ConversationBlock}>
            {batchStdent.length === 0 ? (
              <div>This is BroadCast Chat Room</div>
            ) : (
              <>
                {batchStdent.length > 0 &&
                  batchStdent
                    ?.filter((students) =>
                      students.username
                        ?.toLowerCase()
                        ?.includes(search.toLowerCase())
                    )
                    ?.map((students, ind) => {
                      return (
                        <div
                          className={Styles.listUser}
                          key={ind}
                          style={{
                            backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            border: "0.3px ridge #999",
                            borderRadius: "6px",
                          }}>
                          <div className={Styles.conversation}>
                            <span>{students.username}</span>
                            <div>
                              <span>
                                {students.notification !== 0
                                  ? students.notification
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <p
                            className={
                              students.lastmsg
                                ? `${Styles.lastmsg}`
                                : `${Styles.Nolastmsg}`
                            }>
                            <span>
                              {students.lastmsg
                                ? students?.lastmsg?.text?.slice(0, 25)
                                : null}
                            </span>
                            {students.createdAt ? (
                              students.createdAt === Date.now ? (
                                <Moment format="h:mm a">
                                  {students.createdAt}
                                </Moment>
                              ) : (
                                dateFormat(students.createdAt, "DDDD h:MM TT")
                              )
                            ) : (
                              // <Moment format='MMM DD YYYY h A'>{students.createdAt}</Moment>
                              ""
                            )}
                          </p>
                        </div>
                      );
                    })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Conversation;
