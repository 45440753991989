import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Styles from "./batch.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import ValidationCreateGroup from "../admin/ValidationCreateGroup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { SocketContext } from "../../ioContext/socketIo";
import { Divider } from "@mui/material";

const UserCreateGroup = () => {
  let navigate = useNavigate();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [errors, setErrors] = useState({});
  let [purposes, setPurposes] = useState([]);
  let [courses, setCourses] = useState([]);
  let [branchs, setBranchs] = useState([]);
  let [allstudents, setAllStudents] = useState([]);
  let [qrCode, setQrCode] = useState(false);
  let [valueid, setsValueId] = useState("");
  const socket = useContext(SocketContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCourse, setIsLoadingCourse] = useState(false);
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);

  let [groupCode, setGroupCode] = useState("");
  let [purpose, setPurpose] = useState("");
  let [course, setCourse] = useState("");
  let [branch, setBranch] = useState("");
  let [startDate, setStartDate] = useState("");
  let [addStudentstogroup, setAddStudentsToGroup] = useState([]);
  let [author, setAuthor] = useState({});

  let [users, setUsers] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  useEffect(() => {
    let data = user.username;
    setUsers(data);
  }, []);

  useEffect(() => {
    let data = user.branch;
    let ccc = data?.map(function (ele) {
      return { value: ele, label: ele };
    });
    setBranchs(ccc);
  }, []);
  const handleChangeStudent = (e) => {
    //(e);
    setAddStudentsToGroup(
      Array.isArray(e) ? e.map((x) => ({ _id: x.value })) : []
    );
  };

  let LoadPurpose = () => {
    async function fetchData() {
      // get Subjects
      await AxiosInstance.get(`auth/getdata?data=purpose`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          let Subjectdataaa = x?.data[0].purpose;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele, label: ele };
          });
          setPurposes(ddd);
        })
        .catch((y) => {
          //(y);
        })
        .finally((z) => {
          setIsLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpen = () => {
    if (purposes.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadPurpose();
    }
  };

  let LoadCourse = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=course`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then((x) => {
          let Coursedataaa = x?.data[0].course;
          let eee = Coursedataaa.map(function (ele) {
            return { value: ele, label: ele };
          });
          setCourses(eee);
        })
        .catch((y) => {
          //(y);
        })
        .finally(() => {
          setIsLoadingCourse(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenCourse = () => {
    if (courses.length === 0 && !isLoadingCourse) {
      setIsLoadingCourse(true);
      LoadCourse();
    }
  };

  // let LoadBranch = () => {
  //   async function fetchData() {
  //     await AxiosInstance.get(`auth/getdata?data=branch`, {
  //       headers: {
  //         Authorization: `Bearer ${user.TOKEN}`,
  //       },
  //     })
  //       .then(x => {
  //         let Branchdataaa = x?.data[0]?.branch;
  //         let ccc = Branchdataaa?.map(function (ele) {
  //           return { value: ele, label: ele };
  //         });
  //         setBranchs(ccc);
  //       })
  //       .catch(y => {
  //         //(y);
  //       })
  //       .finally(() => {
  //         setIsLoadingBranch(false);
  //       });
  //   }
  //   fetchData();
  // };
  // const handleMenuOpenBranch = () => {
  //   if (branchs.length === 0 && !isLoadingBranch) {
  //     setIsLoadingBranch(true);
  //     LoadBranch();
  //   }
  // };
  let handleInputChangeStudent = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change" || inputValue === "") {
      let StudentData = AxiosInstance.get(
        `users/getstudents?search=${inputValue}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      let Studentdataaa = StudentData?.data?.students;

      setAllStudents(Studentdataaa);
    }
  };
  let LoadStudents = () => {
    async function fetchData() {
      //   // get Students
      let StudentData = await AxiosInstance.get(`users/getstudents`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });

      let Studentdataaa = StudentData?.data?.students;

      let eee = Studentdataaa.map(function (ele) {
        return {
          value: ele._id,
          label: ele.username + "-" + ele.number.toString().substring(6, 10),
        };
      });
      setAllStudents(eee);
    }
    fetchData();
  };

  const handleMenuOpenSubject = () => {
    // if (allstudents?.length === 0 && !isLoadingStudents) {
    setIsLoadingStudents(true);
    LoadStudents();
    // }
  };
  // useEffect(() => {
  // async function fetchData() {
  //   //   // get Students
  //   let StudentData = await AxiosInstance.get(
  //     `users/getstudents?search=${searchQuery}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${user.TOKEN}`,
  //       },
  //     }
  //   );

  //   let Studentdataaa = StudentData?.data?.students;

  //   let eee = Studentdataaa.map(function (ele) {
  //     //(ele)
  // return {
  //   value: ele._id,
  //   label: ele.username + "-" + ele.number.toString().substring(6, 10),
  // };
  //   });
  //   setAllStudents(eee);
  // }
  // fetchData();
  // }, [user._id, searchQuery]);
  useEffect(() => {
    const handleNotification = (data) => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification_new", handleNotification);

    return () => {
      socket.off("notification_new", handleNotification);
    };
  }, []);

  const handleChangeBranch = (e) => {
    if (e?.value) {
      setBranch(e.value);
    } else {
      setBranch("");
    }
  };

  const handleChangePurpose = (e) => {
    if (e?.value) {
      setPurpose(e.value);
    } else {
      setPurpose("");
    }
  };
  const handleChangeCourse = (e) => {
    setAuthor({
      id: user?._id,
      name: user?.username,
    });
    if (e?.value) {
      setCourse(e.value);
    } else {
      setCourse("");
    }
  };
  let handleRole = (e) => {
    setUsers([user.username]);
  };
  let handleSubmit = async (e) => {
    e.preventDefault();

    if (
      groupCode.trim() === "" ||
      purpose === "" ||
      course === "" ||
      branch === "" ||
      startDate === "" ||
      branch === ""
    ) {
      setErrors(
        ValidationCreateGroup(
          groupCode,
          purpose,
          course,
          branch,
          startDate,
          addStudentstogroup,
          author
        )
      );
    } else {
      try {
        // trainer = {(user.role):(user.username)}
        if (user.role === "trainer") {
          let trainer = users;

          let groupName = groupCode?.trim();
          //(groupCode);
          let payload = {
            groupCode: groupName,
            purpose,
            course,
            branch,
            trainer,
            startDate,
            addStudentstogroup,
            author,
          };
          //(payload);
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then((x) => {
              //(x);
              //(x.data.groupData._id);
              let value = {
                Group_Id: x.data.groupData._id,
                Group_Name: x.data.groupData.groupCode,
                User_Id: user._id,
                User_Name: user.username,
                Role: user.role,
                Branch: "",
                Purpose: "",
              };
              //(value);
              setsValueId(JSON.stringify(value));
              setQrCode(true);
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              // navigate("/user-dashboard/usergrouplist");
            })
            .catch((x) => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
          //(data);
        } else if (user.role === "tracker") {
          let tracker = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            tracker,
            startDate,
            addStudentstogroup,
            author,
          };
          //(payload)
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then((x) => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              navigate("/user-dashboard/usergrouplist");
            })
            .catch((x) => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
          //(data);
        } else if (user.role === "hr") {
          let hr = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            hr,
            startDate,
            addStudentstogroup,
            author,
          };
          //(payload)
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then((x) => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              navigate("/user-dashboard/usergrouplist");
            })
            .catch((x) => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
          //(data);
        } else {
          let counselor = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            counselor,
            startDate,
            addStudentstogroup,
            author,
          };
          //(payload)
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then((x) => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              navigate("/user-dashboard/usergrouplist");
            })
            .catch((x) => {
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
        }
      } catch (error) {
        //(error);
      }
    }
  };

  return (
    <>
      <section id={Styles.CreateGroup}>
        {qrCode === true ? (
          <>
            <div className={Styles.Qrcode}>
              <QRCode
                value={valueid}
                size={256}
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="H"
                includeMargin={false}
                renderAs="svg"
              />
              <div>
                {" "}
                Scan the QR code to join Group{" "}
                <span style={{ color: "blue" }}>{valueid?.groupCode}</span>
              </div>
            </div>
            {/* <button>Download QRCOde</button> */}
          </>
        ) : (
          <main
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#E8E8E8",
            }}>
            <div className={Styles.form_design}>
              <h3
                style={{
                  color: "#f57c00",
                  fontWeight: "bold",
                  textDecoration: "none",
                  textAlign: "center",
                }}>
                Create Group
              </h3>
              <Divider />
              <br />
              <form onSubmit={handleSubmit}>
                <div>
                  <h6 htmlFor="groupCode">
                    GroupCode <b style={{ color: "#f57c00" }}>*</b>
                  </h6>
                  <input
                    className="form-control"
                    type="text"
                    name="groupCode"
                    value={groupCode}
                    placeholder="Enter GroupCode"
                    onChange={(e) => setGroupCode(e.target.value)}
                  />
                  {errors.groupCode && (
                    <p className={Styles.errors}>{errors.groupCode}</p>
                  )}
                </div>
                <aside>
                  <h6 htmlFor="branch">
                    Branch <b style={{ color: "#f57c00" }}>*</b>
                  </h6>
                  <Creatable
                    // onMenuOpen={handleMenuOpenBranch}
                    // isLoading={isLoadingBranch}
                    isClearable
                    labelKey="name"
                    // className={Styles.option}
                    options={branchs}
                    onChange={handleChangeBranch}
                  />
                  {errors.branch && (
                    <p className={Styles.errors}>{errors.branch}</p>
                  )}
                </aside>
                <aside>
                  <h6 htmlFor="course">
                    course <b style={{ color: "#f57c00" }}>*</b>
                  </h6>
                  <Creatable
                    onMenuOpen={handleMenuOpenCourse}
                    isLoading={isLoadingCourse}
                    isClearable
                    labelKey="name"
                    // className={Styles.option}
                    options={courses}
                    onChange={handleChangeCourse}
                  />
                  {errors.course && (
                    <p className={Styles.errors}>{errors.course}</p>
                  )}
                </aside>
                <aside>
                  <h6 htmlFor="purpose">
                    Purpose <b style={{ color: "#f57c00" }}>*</b>
                  </h6>
                  <Creatable
                    onMenuOpen={handleMenuOpen}
                    isLoading={isLoading}
                    isClearable
                    labelKey="name"
                    options={purposes}
                    onChange={handleChangePurpose}
                    // className={Styles.option}
                  />
                  {errors.purpose && (
                    <p className={Styles.errors}>{errors.purpose}</p>
                  )}
                </aside>
                <div>
                  <h6 htmlFor="role">User</h6>

                  <input
                    readOnly={true}
                    type="text"
                    defaultValue={user.username}
                    name={`${user.role}`}
                  />
                </div>
                <div>
                  <h6 htmlFor="startDate">
                    Start Date <b style={{ color: "#f57c00" }}>*</b>
                  </h6>
                  <input
                    className="form-control"
                    type="date"
                    name="startDate"
                    min={disablePastDate()}
                    // required
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  {errors.startDate && (
                    <p className={Styles.errors}>{errors.startDate}</p>
                  )}
                </div>

                <article>
                  <h6 htmlFor="addStudentstogroup">
                    Add Students <b style={{ color: "#f57c00" }}>*</b>
                  </h6>

                  {/* <Select
                    closeMenuOnSelect={false}
                    isMulti
                    // inputValue={searchQuery}
                    // onMenuOpen={handleMenuOpen}
                    onInputChange={handleInputChange}
                    name="addStudentstogroup"
                    options={allstudents}
                    onChange={handleChangeStudent}
                    onMenuScrollToBottom
                  /> */}
                  <Select
                    isMulti
                    isLoading={isLoadingStudents}
                    onChange={handleChangeStudent}
                    onInputChange={handleInputChangeStudent}
                    onMenuOpen={handleMenuOpenSubject}
                    options={allstudents}
                    closeMenuOnSelect={false}
                    // value={addStudentstogroup}
                  />
                  {errors.addStudentstogroup && (
                    <p className={Styles.errors}>{errors.addStudentstogroup}</p>
                  )}
                </article>

                <div className={Styles.button_color}>
                  <button>Add Group</button>
                </div>
              </form>
            </div>
          </main>
        )}
      </section>
    </>
  );
};

export default UserCreateGroup;
