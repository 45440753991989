import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";

import { SocketContext } from "../../../ioContext/socketIo";
import Conversation from "./Conversation";
import Message from "./Message";
import UserNameData from "./UserNameData";

const AdminGroupChatComp = () => {
  let { groupCode } = useParams();
  let { userAdmin } = useSelector(state => state?.auth);
  const socket = useContext(SocketContext);
  let scrollRef = useRef();

  // slected batch data
  let [selctBatchStud, setSelctBatchStud] = useState([]);
  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState({});
  let [batchTrainerData, setBatchTrainerData] = useState(null);
  // conversations

  let [allUsers, setAllUsers] = useState([]);
  let [groupname, setGroupName] = useState("");

  // messages
  let [messages, setMessages] = useState([]);

  const [arrivalMessage, setArrivalMessage] = useState([]);

  const [number1, SetNumber1] = useState(0);
  // api
  useEffect(() => {
    async function fetchlist() {
      await AxiosInstance.get(
        `admin/groupList/${encodeURIComponent(groupCode)}?scroll=${number1}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      ).then(data => {
        //(data)
        //(data)
        let payload = data?.data?.groupData;
        let groupName = data?.data?.groupData?.batchCode;
        let studentData = data?.data?.studentData;
        //(studentData)
        if (studentData?.length === 0) {
          SetNumber1(0);
        }
        let trainersData = data?.data?.trainertrackerData;
        let trainersDataaaa = data?.data?.trainertrackerData?.filter(x => {
          if (x.role === "trainer") {
            return x;
          } else if (x.role === "counselor") {
            return x;
          } else if (x.role === "hr" || x.role == "human resource") {
            return x;
          }
        });
        let trainerId = trainersDataaaa[0]?._id;
        //(trainersDataaaa)
        let trainername =
          trainersDataaaa[0]?.username + "-" + trainersDataaaa[0]?.role;
        setBatchTrainerData(trainerId);

        setSelctBatchStud(payload);
        setBatchStdentData(studentData);
        // setBatchTrainerData(trainersData);
        setGroupName(groupName);
        setAllUsers(trainersData);
      });
    }
    fetchlist();
  }, [number1]);

  useEffect(() => {
   

    socket.on("getMessage_new", (data) => {
      if (data.brodcastchat) {
        setArrivalMessage(data);
      } else {
        setArrivalMessage(data);
      }
    });
  }, [userAdmin._id]);
  useEffect(() => {
    setMessages(prev => [...prev, arrivalMessage]);
  }, [particularStudent.studentId && arrivalMessage]);

  useEffect(() => {
    const getconversations = async () => {
      try {
        if (batchTrainerData && particularStudent?.studentId !== "") {
        

          const res = await AxiosInstance.post(
            "/chat/admin/msg/new/allmsg",
            {
              senderId: batchTrainerData,
              receiverId: particularStudent.studentId,
              userId: batchTrainerData,
              studentId: particularStudent.studentId,
            },
            {
              headers: {
                Authorization: `Bearer ${userAdmin.TOKEN}`,
              },
            }
          );
          res.data.msgs.reverse();

          if (!res?.data?.msgs || res?.data?.msgs?.length <= 200) {
            const des1 = await AxiosInstance.post(
              "/chat/admin/msg/old/allmsg",
              {
                senderId: batchTrainerData,
                receiverId: particularStudent?.studentId,
                userId: batchTrainerData,
                studentId: particularStudent?.studentId,
              },
              {
                headers: {
                  Authorization: `Bearer ${userAdmin.TOKEN}`,
                },
              }
            );
            // //(des1)
           des1?.data?.reverse();
            setMessages(des1?.data?.concat(res?.data?.msgs));
          } else {
            // //("hiii")
            setMessages(res?.data?.msgs);
          }
        }
      } catch (error) {
        //(error);
      }
    };
    getconversations();
  }, [batchTrainerData && particularStudent?.studentId]);
  //(batchTrainerData)
  // scroll for msg
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [messages]);

  return (
    <>
      <section id={Styles.chatBoxSection}>
        <article>
          <aside className={Styles.chatMenu}>
            <Conversation
              batchStdent={batchStdentData}
              setParticularStudent={setParticularStudent}
              trainername={selctBatchStud}
              allUsers={allUsers}
              setBatchTrainerData={setBatchTrainerData}
              groupname={groupname}
              SetNumber1={SetNumber1}
              number1={number1}
              groupCode={groupCode}
              setBatchStdentData={setBatchStdentData}
            />
          </aside>
          <aside className={Styles.chatBox}>
            <div className={Styles.chatBoxWrapper}>
              {particularStudent.studentId ? (
                <>
                  <div className={Styles.chatBoxTop}>
                    <UserNameData name={particularStudent} />
                    <div className={Styles.chatBoxStore}>
                      {messages.length > 0 ? (
                        messages.map((m, ind) => (
                          <Fragment key={ind}>
                            <Message
                              particularStudent={particularStudent}
                              message={m}
                              own={m?.sender === batchTrainerData}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <span className={Styles.noConversation}>
                          <p>No Messages</p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div ref={scrollRef} style={{ visibility: "hidden" }}>
                    top
                  </div>
                  {/* <div className={Styles.chatBoxBottom}>
                      <input
                        className="chatMessageInput"
                        placeholder="write something..."
                        onChange={(e) => {
                          setNewMessage(e.target.value);
                        }}
                        value={newMessage}
                      ></input>
                      <aside
                        style={{ position: "relative" }}
                        className={Styles.UploadfileContainer}
                      >
                        <span>
                          <ImAttachment
                            onClick={() => setIsOpen(true)}
                          ></ImAttachment>
                          {isOpen && (
                            <Modal
                              setIsOpen={setIsOpen}
                              particularStudent={particularStudent}
                              conversations={conversations}
                              setMessages={setMessages}
                              messages={messages}
                            />
                          )}
                        </span>
                      </aside>
                      <button className="chatSubmitButton" onClick={handelSubmit}>
                        Send
                      </button>
                    </div> */}
                </>
              ) : (
                <span className={Styles.noConversationText}>
                  <p>Open a conversation to start a chat.</p>
                </span>
              )}
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};

export default AdminGroupChatComp;

// export default AdminGroupChatComp
