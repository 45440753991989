import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import UserBroadGroup from "./Broadcast/UserBroadGroup";
import UserGroupComp from "./Chat/UserGroupComp";
import { globalContext } from "./UsersContext";
import { toast } from "react-toastify";
const SelectedGroupList = () => {
  let navigate = useNavigate();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let { switchComp } = useContext(globalContext);
  let { groupCode } = useParams();

  useEffect(() => {
    if (user) {
      async function fetchlist() {
        AxiosInstance.get(
          `users/groups/changes_new/${encodeURIComponent(groupCode)}`,
          {
            headers: {
              Authorization: `Bearer ${user?.TOKEN}`,
            },
          }
        )
          .then(data => {
            let studentData = data?.data?.studentsCount;
            if (studentData <= 0) {
              toast.error("No students present");
              setTimeout(() => {
                navigate(-1);
              }, 100);
            }
          })
          .catch(x => {
            if (x?.response?.status === 403) {
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("device");
              Cookies.remove("name", { path: "", domain: ".qspiders.com" });
              navigate("/");
              Cookies.remove("student", { path: "", domain: ".qspiders.com" });
              Cookies.remove("visitedHomePage", {
                path: "",
                domain: ".qspiders.com",
              });
            } else if (x?.response?.status === 404) {
              toast.error("this group is closed");
              setTimeout(() => {
                navigate(-1);
              }, 100);
            }
          });
      }
      fetchlist();
    }
  }, [user]);

  return (
    <div>
      <div>
        {user?.TOKEN ? (
          switchComp ? (
            <UserGroupComp />
          ) : (
            <UserBroadGroup />
          )
        ) : (
          "loading111..."
        )}
      </div>
    </div>
  );
};

export default SelectedGroupList;

// export default SelectedGroupList
