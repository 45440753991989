import React, { useContext, useEffect } from "react";
import { useState } from "react";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "./_Status.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
  IoMdSend,
} from "react-icons/io";
import { FcPlus } from "react-icons/fc";
import { AiFillDelete } from "react-icons/ai";
import { FaTasks } from "react-icons/fa";
import { ImCross, ImAttachment } from "react-icons/im";
import { Modal } from "rsuite";
import { SocketContext } from "../../ioContext/socketIo";
import { TbCircleDashed } from "react-icons/tb";
import { CirclePicker } from "react-color";
import { Dropdown } from "rsuite";
import { useNavigate } from "react-router-dom";
import Linkify from "react-linkify";
import img1 from "../../assets/noQuiz.webp";
import { Box, Button as MUIButton, Modal as MUIModal } from "@mui/material";
import handleError from "./ErrorHandler";
import fetchImageUrl from "../functions/FetchFileWithToken";

const styleModal2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "2px solid #f57c00",
};

const Status = () => {
  let navigate = useNavigate();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [userData, setUserData] = useState([]);
  let [userListStatus, setUserListStatus] = useState([]);
  let [mySatus, setMyStatus] = useState([]);
  // const [currentSlide, setCurrentSlide] = useState(0);
  let [statusClick, setStatusClick] = useState(false);

  const [status, setStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCamera, setIsEditingCamera] = useState(false);

  const [initialStatus, setInitialStatus] = useState("");
  let [files, setNewFiles] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const [deletestatus, setDeleteStatus] = useState(null);

  //modal
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  let [ListReplay, setListReplay] = useState(null);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [carouselType, setCarouselType] = useState("");
  const [selectedText, setSelectedText] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedToDeleteReply, setSelectedToDeleteReply] = useState(null);

  const handleImageClick = imageUrl => {
    setSelectedImage(imageUrl);
    setOpenPreviewModal(true);
  };

  const handleTextClick = (text, color) => {
    setSelectedText({ text, color });
    setOpenPreviewModal(true);
  };

  const handleClose = () => setOpen(false);
  const handleCloseModal = () => setOpenModal(false);

  const [display, setDisplay] = useState(false);
  const [displayCamera, setDisplayCamera] = useState(false);

  const [newMessage, setNewMessage] = useState("");

  const socket = useContext(SocketContext);

  const initialColor = {
    Red: 21,
    Green: 101,
    Blue: 192,
    Alpha: 255,
  };
  const [color, setColor] = useState(initialColor); // Initial color is red

  const handleChangeComplete = selectedColor => {
    const { r, g, b, a } = selectedColor.rgb;
    const newColor = {
      Red: r,
      Green: g,
      Blue: b,
      Alpha: a * 255, // The alpha value in the color picker is normalized (0 to 1), so we need to scale it to 0 to 255.
    };
    setColor(newColor);
  };
  // const rgbaColor = `rgba(${color.Red}, ${color.Green}, ${color.Blue}, ${
  //   color.Alpha / 255
  // })`;
  // Assuming color is an object with Red, Green, Blue, and Alpha properties
  const rgbaColor = `rgba(${Math.min(color.Red + 100, 255)}, ${Math.min(
    color.Green + 100,
    255
  )}, ${Math.min(color.Blue + 100, 255)}, ${color.Alpha / 255})`;

  //(rgbaColor);
  let handleClickLogout = e => {
    //(e);
    setDeleteStatus(e);
    //("modal");
    setOpen(true);
  };
  //(deletestatus);
  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification_new", handleNotification);

    return () => {
      socket.off("notification_new", handleNotification);
    };
  }, [socket]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setDisplay(true);
    setInitialStatus(status);
  };

  const handleSaveClick = () => {
    //(status.length);
    setIsEditing(false);
    setDisplay(false);
    if (status?.length === 0 || status === "") {
      //("type something");
    } else {
      JSON.stringify(color);
      let payload = {
        userId: user._id,
        username: user.username,
        text: status,
        colour: JSON.stringify(color),
      };
      //(payload);
      async function students() {
        await AxiosInstance.post(`/employee/uploadstatus`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            console?.log(x);
            setStatus("");
          })
          .catch(x => {
            console?.log(x);
            setStatus("");
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
      students();
    }
  };
  let handleSaveClickImage = () => {
    setDisplayCamera(false);
    setIsEditingCamera(false);
    let image = { files: files };

    async function students() {
      const options = {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await AxiosInstance.post("/chat/newmsgfiles", image, options)
        .then(x => {
          //(x);
          let data = x.data.files[0];
          //(data);
          let payload = {
            userId: user._id,
            username: user.username,
            files: [data],
            files_text: newMessage,
          };
          AxiosInstance.post(`/employee/uploadstatus`, payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              //(x);
              setStatus("");
            })
            .catch(x => {
              //(x);
              setStatus("");
              if (x?.response?.status === 403) {
                handleError(navigate, socket);
              }
            });
        })
        .catch(y => {
          //(y);
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };
  const handleCancelClick = () => {
    setDisplay(false);
    setIsEditing(false);
    setStatus(initialStatus); // Restore the initial status when the user cancels
    setImagePreview(null); // Clear the image preview on cancel
  };
  let handleCancelClickImage = () => {
    setIsEditingCamera(false);
    setDisplayCamera(false);
    setStatus(initialStatus); // Restore the initial status when the user cancels
    setImagePreview(null);
  };
  const handleChange = e => {
    setStatus(e.target.value);
  };
  useEffect(() => {
    let payload = { userId: user?._id };
    async function students() {
      await AxiosInstance.post(`/employee/statusUsersList`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          //(x);
          let data = x.data.findStatus;
          //(data);
          let list = data.findIndex(obj => obj.userId === user._id);
          //(list);
          if (list !== -1) {
            // If the object was found (index is not -1), remove it from the array
            data.splice(list, 1);
          } else {
            //("Object with the specified ID not found in the array.");
          }

          setUserData(data);
        })
        .catch(x => {
          //(x);
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  }, []);

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        userListStatus.map(async ele => {
          if (ele?.status?.message?.files) {
            const fileData = await fetchImageUrl(
              ele?.status?.message?.files[0]?.path,
              user?.TOKEN
            );
            if (fileData) {
              newFileUrls[ele?.status?.message?.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [userListStatus]);

  let handleStatus = e => {
    //(e);
    let payload = { userId: e.userId };
    //(payload);
    async function students() {
      await AxiosInstance.post(`/employee/viewStatus`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          //(x);
          let data = x.data.findStatus;
          //(data);
          setUserListStatus(data);
        })
        .catch(x => {
          //(x);
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };
  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  let handleStatusOwn = () => {
    let payload = { userId: user?._id };
    async function students() {
      await AxiosInstance.post(`/employee/viewStatus`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          let data = x.data.findStatus;

          setUserListStatus(data);
          setMyStatus(data);
        })
        .catch(x => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {});
    }
    students();
  };
  const renderCustomPrevArrow = (onClickHandler, hasPrev) => {
    return (
      hasPrev && (
        <button onClick={onClickHandler} className={Styles.customPrevArrow}>
          <IoMdArrowDropleftCircle />
        </button>
      )
    );
  };

  const renderCustomNextArrow = (onClickHandler, hasNext) => {
    return (
      hasNext && (
        <button onClick={onClickHandler} className={Styles.customNextArrow}>
          <IoMdArrowDroprightCircle />
        </button>
      )
    );
  };

  const handleImageUpload = e => {
    setDisplayCamera(true);
    if (e.target.files.length !== 0) {
      setIsEditingCamera(!isEditingCamera);
      const file = e.target.files[0];
      //(file);
      //(e.target.files.length);
      setNewFiles(file);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  let HandleDeleteStatus = () => {
    let data = deletestatus.status.status_id;
    let payload = { userId: user._id, status_id: data };
    //(payload);
    async function students() {
      await AxiosInstance.post(`/employee/statusDelete`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          //(x);
          handleStatusOwn();
        })
        .catch(x => {
          //(x);
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(() => {
          handleClose();
        });
    }
    students();
  };
  let handleClickCalcel = () => {
    setIsEditing(false);
    setIsEditingCamera(false);
    setUserListStatus([]);
    setStatusClick(false);
  };

  let handleListReplay = e => {
    setOpenModal(true);

    let data = e.status.status_id;

    async function students() {
      let payload = { userId: user._id, status_id: data };
      await AxiosInstance.post(`/employee/status_replied`, payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.data?.replied;
          setListReplay(data);
        })
        .catch(x => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    students();
  };
  return (
    <section className={Styles.StatusBlock}>
      <MUIModal
        open={openPreviewModal}
        onClose={() => {
          setOpenPreviewModal(false);
          setCarouselType("");
          setSelectedText(null);
          setSelectedImage(null);
        }}
        closeAfterTransition
      >
        {/* <Fade in={openPreviewModal}> */}
        <Box sx={styleModal2}>
          <div style={{ textAlign: "center", position: "relative" }}>
            <button
              style={{
                position: "absolute",
                right: "-10px",
                background: "transparent",
                top: "-10px",
              }}
              onClick={() => {
                setOpenPreviewModal(false);
                setCarouselType("");
                setSelectedText(null);
                setSelectedImage(null);
              }}
            >
              <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                <path
                  fill="crimson"
                  d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                ></path>
              </svg>
            </button>{" "}
          </div>
          {carouselType === "text" ? (
            <div
              style={{
                width: "100%",
                // minWidth: "600px",
                // maxWidth: "700px",
                background: selectedText?.color,
                minHeight: "80vh",
                maxHeight: "85vh",
                borderRadius: "6px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                overflow: "auto",
                // wordBreak: "break-all",
                padding: "0.5%",
                fontSize: "large",
                whiteSpace: "pre-wrap",
              }}
            >
              {selectedText?.text}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto",
              }}
            >
              <img
                src={selectedImage}
                alt="Full Size"
                style={{
                  minHeight: "60vh",
                  maxWidth: "100%",
                  maxHeight: "80vh",
                }}
              />
            </div>
          )}
        </Box>
        {/* </Fade> */}
      </MUIModal>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={openModal}
        onClose={handleCloseModal}
        size="xs"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Modal.Header> */}
        {ListReplay === undefined ? (
          <h5
            style={{
              color: "#f57c00",
              textDecoration: "underline",
            }}
          >
            No replied student
          </h5>
        ) : (
          <h5
            style={{
              color: "#f57c00",
              textDecoration: "underline",
            }}
          >
            Replied Student List
          </h5>
        )}
        {/* </Modal.Header> */}

        <Modal.Body style={{ fontSize: "16px" }}>
          {ListReplay?.map((x, i) => {
            return (
              <div
                onClick={e => {
                  e.stopPropagation();
                }}
                className={Styles.ListUser}
                key={i}
                style={{
                  backgroundImage:
                    i % 2 === 0
                      ? `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`
                      : `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlXLm169P0IIPVSlSTI2gNkqihXI2jNcHMbfaJ_xlkm6L1leFed3LhgkUYfAw0PCrSDRY&usqp=CAU')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  border: "0.3px ridge #999",
                  borderRadius: "6px",
                }}
              >
                <h6> {x.sendername}</h6>
                <p>{x?.messages?.text.slice(0, 80)}</p>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <MUIButton
            color="warning"
            size="small"
            variant="outlined"
            onClick={e => {
              e.stopPropagation();
              handleCloseModal();
            }}
          >
            Cancel
          </MUIButton>
        </Modal.Footer>
      </Modal>
      {/* delete modal */}
      <Modal
        onClick={e => {
          e.stopPropagation();
        }}
        backdrop="static"
        role="alertdialog"
        open={open}
        // onClose={handleClose}
        size="xs"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Body style={{ fontSize: "16px" }}>
          Do you Want To Delete this Status?
        </Modal.Body>
        <Modal.Footer>
          <MUIButton
            color="warning"
            variant="contained"
            // onClick={HandleDeleteStatus(ele)}
            onClick={() => HandleDeleteStatus()}
            size="small"
          >
            Ok
          </MUIButton>
          &nbsp;
          <MUIButton size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </MUIButton>
        </Modal.Footer>
      </Modal>
      <article>
        <aside className={Styles.UserStatusBlock}>
          <div
            className={Styles.ListUser1}
            onClick={e => {
              handleStatusOwn();
              // {
              //   mySatus.length > 0 && handleStatusOwn();
              // }
              setStatusClick(true);
            }}
            style={{ background: "yellow !important" }}
          >
            <span> My Status</span>
            {mySatus.length > 0 ? (
              <span className={Styles.StatusColorGreen} title="view my status">
                {" "}
                <TbCircleDashed />
              </span>
            ) : (
              <span className={Styles.StatusColor} title="view my status">
                {" "}
                <TbCircleDashed />
              </span>
            )}
          </div>
          {userData.length < 1 ? (
            <div className={Styles.noStatusView}>
              <img src={img1} alt="" />
              <h3>No status updated</h3>
            </div>
          ) : (
            userData?.map((ele, ind) => {
              return (
                <div
                  key={ind}
                  className={Styles.ListUser}
                  onClick={e => {
                    handleStatus(ele);
                    setStatusClick(true);
                  }}
                >
                  {ele?.username}
                </div>
              );
            })
          )}
        </aside>
        <aside
          className={Styles.MyStatusBlock}
          style={{
            backgroundImage: `url('https://img.freepik.com/free-photo/high-angle-white-modern-geometrical-background_23-2148811514.jpg?size=626&ext=jpg&ga=GA1.1.1318602343.1709182378&semt=ais')`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {statusClick === true && userListStatus.length > 0 ? (
            <div className={Styles.MyStatus}>
              <Carousel
                showArrows={true}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                // selectedItem={currentSlide}
                renderArrowPrev={renderCustomPrevArrow}
                renderArrowNext={renderCustomNextArrow}
              >
                {userListStatus?.length > 0 &&
                  userListStatus?.map((ele, ind) => {
                    const colorObj =
                      ele?.status?.colour !== null
                        ? JSON.parse(ele?.status?.colour)
                        : "";
                    const colorString = `rgba(${Math.min(
                      colorObj.Red + 100,
                      255
                    )}, ${Math.min(colorObj.Green + 100, 255)}, ${Math.min(
                      colorObj.Blue + 100,
                      255
                    )}, ${colorObj.Alpha / 255})`;
                    const divStyle = {
                      backgroundColor: colorString,
                    };

                    const removeBtnIcon = ele.userId === user._id;

                    return (
                      <div className={Styles.content} key={ind}>
                        {ele?.status?.message?.text ? (
                          <>
                            <div
                              className={Styles.heading}
                              style={divStyle}
                              onClick={() => {
                                setCarouselType("text");
                                handleTextClick(
                                  ele?.status?.message?.text,
                                  colorString
                                );
                              }}
                            >
                              <Linkify componentDecorator={openInNewTab}>
                                <div
                                  className={Styles.TextBox1}
                                  disabled
                                  style={{
                                    // wordBreak: "break-all",
                                    whiteSpace: "pre-wrap",
                                    padding: "0.5%",
                                  }}
                                >
                                  {ele?.status?.message?.text}
                                </div>
                              </Linkify>

                              <div className={Styles.CrossMarkBlock}>
                                <span
                                  className={Styles.CancelStatus}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleClickCalcel(ele);
                                  }}
                                  title="Close"
                                >
                                  <ImCross color="crimson" />
                                </span>
                              </div>
                              {!removeBtnIcon ? null : (
                                <span
                                  className={Styles.ListStatus}
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleListReplay(ele);
                                  }}
                                  title="Replied Users"
                                >
                                  <FaTasks color="#f57c00" />
                                </span>
                              )}

                              {ele.userId !== user._id ? null : (
                                <span
                                  className={Styles.DeleteStatus}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSelectedToDeleteReply(ele);
                                    handleClickLogout(ele);
                                  }}
                                  title="Delete Status"
                                >
                                  <AiFillDelete />
                                </span>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={Styles.heading}
                            onClick={() => {
                              setCarouselType("image");
                              handleImageClick(
                                imageUrls[ele?.status?.message?.files[0]?.path]
                                  ?.url
                              );
                            }}
                          >
                            <img
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "80%",
                              }}
                        
                              src={
                                imageUrls[ele?.status?.message?.files[0]?.path]
                                  ?.url
                              }
                              alt=""
                            />
                            <div
                              className={Styles.StatusMsg}
                              style={{
                                // wordBreak: "break-all",
                                whiteSpace: "pre-wrap",
                                fontSize: "medium",
                              }}
                            >
                              {ele?.status?.message?.files_text}
                            </div>
                            <div className={Styles.CrossMarkBlock}>
                              <span
                                style={{ zIndex: 999 }}
                                className={Styles.CancelStatus}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleClickCalcel(ele);
                                }}
                                title="close"
                              >
                                <ImCross color="crimson" />
                              </span>
                            </div>

                            {!removeBtnIcon ? null : (
                              <span
                                style={{ zIndex: 999 }}
                                className={Styles.ListStatus}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleListReplay(ele);
                                }}
                                title="Replied Users"
                              >
                                <FaTasks color="#f57c00" />
                              </span>
                            )}

                            {ele.userId !== user._id ? null : (
                              <span
                                style={{ zIndex: 999 }}
                                className={Styles.DeleteStatus}
                                onClick={e => {
                                  e.stopPropagation();
                                  setSelectedToDeleteReply(ele);
                                  handleClickLogout(ele);
                                }}
                                title="delete status"
                              >
                                <AiFillDelete />
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          ) : (
            <div className={Styles.statuscontainer}>
              <div className={Styles.statusheader}>
                <span style={{ color: "#f57c00" }}>My Status</span>
                <div className={Styles.Edit}>
                  {!isEditing && (
                    <button
                      className={
                        displayCamera === false
                          ? ` ${Styles.editbutton}`
                          : `${Styles.editcameradisplay}`
                      }
                      onClick={handleEditClick}
                    >
                      <FcPlus />
                    </button>
                  )}
                  {!isEditingCamera && (
                    <label
                      htmlFor="image-upload"
                      className={
                        display === false
                          ? `${Styles.editcamera}`
                          : `${Styles.editcameradisplay}`
                      }
                    >
                      <ImAttachment />
                    </label>
                  )}
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <div className={Styles.statuscontent}>
                {!isEditing && !isEditingCamera ? (
                  <div>
                    <p>{status || "Click the icon to add your status."}</p>
                  </div>
                ) : (
                  ""
                )}
                {isEditing ? (
                  <>
                    <textarea
                      id="status"
                      style={{ background: rgbaColor, color: "black" }}
                      className={Styles.TextBox}
                      autoFocus
                      value={status}
                      onChange={handleChange}
                      placeholder="Type your status here..."
                    />
                    <Dropdown title="colors" placement="topStart">
                      <div style={{ textAlign: "center", marginTop: "50px" }}>
                        {/* <h1>React Circle Picker Example</h1> */}
                        <CirclePicker
                          color={rgbaColor}
                          onChangeComplete={handleChangeComplete}
                        />
                        <div style={{ marginTop: "20px" }}>
                          Selected Color:{" "}
                          <span style={{ color: rgbaColor }}>{rgbaColor}</span>
                        </div>
                      </div>
                    </Dropdown>

                    <div className={Styles.ButtonBlock}>
                      <button
                        className={Styles.cancelbutton}
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={status?.length === 0 || status === ""}
                        className={Styles.savebutton}
                        onClick={handleSaveClick}
                      >
                        Send
                      </button>
                    </div>
                  </>
                ) : isEditingCamera ? (
                  <>
                    {imagePreview && (
                      <>
                        <img
                          style={{
                            objectFit: "cover",
                            width: "100%",
                          }}
                          src={imagePreview}
                          alt="Status Preview"
                        />
                      </>
                    )}
                    <div className={Styles.MessageBlock1}>
                      <input
                        required
                        autoFocus
                        className={Styles.chatMessageInput1}
                        placeholder="Add Text..."
                        onChange={e => {
                          setNewMessage(e.target.value);
                        }}
                        value={newMessage}
                        // onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div>
                      <button
                        className={Styles.savebutton}
                        onClick={handleSaveClickImage}
                      >
                        <IoMdSend />
                      </button>
                      <button
                        className={Styles.cancelbutton}
                        onClick={() => {
                          setNewMessage("");
                          handleCancelClickImage();
                        }}
                      >
                        <ImCross />
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </aside>
      </article>
    </section>
  );
};

export default Status;
