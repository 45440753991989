import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";
import { ImAttachment } from "react-icons/im";
import { SocketContext } from "../../../ioContext/socketIo";
import StuMessage from "./StuMessage";
import StudentName from "./StudentName";
import StuConversation from "./StuConversation";
import { RotatingLines } from "react-loader-spinner";
import { Tooltip, Whisper } from "rsuite";
import { Modal, ButtonToolbar } from "rsuite";
import FileUpload from "./StudFileUpload";
import { toast } from "react-toastify";
import bg6 from "../../../assets/bg6.webp";
import { ImSpinner11 } from "react-icons/im";
import { AiFillDelete } from "react-icons/ai";
import wavEncoder from "wav-encoder";
import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import handleError from "../../Users/ErrorHandler";

const StudentChatComp = () => {
  let navigate = useNavigate();
  let { batchCode } = useParams();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  const socket = useContext(SocketContext);
  let scrollRef = useRef();
  let [loading, setLoading] = useState(false);

  let [selctBatchStud, setSelctBatchStud] = useState([]);

  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState({});

  // messages
  let [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);
  const [batchname, setBatchname] = useState("");
  const [msgseen, setMsgSeen] = useState("");

  const [open, setOpen] = React.useState(false);
  // const [backdrop, setBackdrop] = React.useState("static");
  let [permenentBlock, setPermanentBlock] = useState(false);

  const [reply, setReply] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };

  //online
  let [online, setOnline] = useState();
  // api
  let [batchBlock, setBatchBlock] = useState(false);
  let [data, setSData] = useState("");

  // voice recoder//

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  // const [recordingStatus, setRecordingStatus] = useState("");
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);

  const [recordedAudio, setRecordedAudio] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  let [msgScroll, setMsgScroll] = useState(0);

  let [messageCount, setMessageCount] = useState(0);
  let [lengthmsg, setLengthMsg] = useState(0);
  let [noContent, setNoContent] = useState(null);

  let CancelReply = () => {
    setReply(false);
    setSelectedMessages([]);
  };

  const handleMessageClick = (clickedMessage, index, isMessageSelected) => {
    const existingIndex = selectedMessages.findIndex(
      (msg) =>
        (msg.messages.text === clickedMessage.messages.text &&
          msg.index === clickedMessage.index) ||
        (msg.messages.flies === clickedMessage?.messages?.flies &&
          msg.index === clickedMessage.index)
    );

    const isMessageAlreadySelected = existingIndex !== -1;
    const hasReachedMaxSelection = selectedMessages.length === 5;

    if (!isMessageAlreadySelected && selectedMessages.length < 5) {
      setSelectedMessages([...selectedMessages, clickedMessage]);
    } else if (isMessageAlreadySelected) {
      const updatedMessages = selectedMessages.filter(
        (_, index) => index !== existingIndex
      );
      setSelectedMessages(updatedMessages);
    } else if (selectedMessages.length === 5) {
      // Alert message when the selection limit is reached or duplicate is clicked
      alert(`You can select only 5 unique messages.`);
    }
  };

  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      const audioData = event.data;
      //(event.data);
      //(audioData);
      setRecordedAudio(audioData);
      setRecordedChunks((prevChunks) => [...prevChunks, event.data]);
    }
  };
  //(recordedAudio);
  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);
    //(recordedAudio);
    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive((prevPlaybackState) => !prevPlaybackState);
    // Clear recorded chunks when playback is triggered
    setRecordedChunks([]);
  };

  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);

  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });

    return URL.createObjectURL(audioBlob);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
      // className={!stop ? `${Styles.AudioNot}` : `${Styles.Audio}`}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  const storeAudioLocally = (audioBlob) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };

  let HandleAudio = async (e) => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = (blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = (arrayBuffer) => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );

      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          //(payload);
          const options = {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          };

          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );

          let filesData = res.data.files;

          socket.emit(
            "sendMessage_new",
            {
              files: filesData,
              senderId: user._id,
              receiverId: particularStudent?.trainersId,
              role: user?.role1,
              code: "",
              type: "batch",
              device: "web",
              poll: null,
            },
            function callback(msg) {
              msg.tick = false;
              setMessages([...messages, msg]);
              setIsPlaybackActive(false);
              setIsAudioVisible(false); // Hide the audio tag
              setRecordedChunks([]); // Clear the recorded audio chunks
              //("end");
              setIsPlaying(false);
            }
          );
          setIsAudioVisible(false); // Hide the audio tag
          setRecordedChunks([]); // Clear the recorded audio chunks
          //("asdasdasda");
          setIsPlaying(false);
        } catch (error) {
          //(error);
        }
      } else {
      }
      // return file;
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on("batchclose_open", (data) => {
        //(data);
        setBatchBlock(true);
        setSData(data.result.res);
      });
    }
  }, [socket]);

  useEffect(() => {
    socket.on("batchclose_open", (data) => {
      //(data);
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {
      // window.location.reload()
      navigate("/student-dashboard");
    }
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }

  useEffect(() => {
    socket.on("blocked", (data) => {
      //(data);
      setPermanentBlock(true);
    });
  }, []);
  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
      // window.location.reload();
    }
    setTimeout(greeting, 3000);
  }

  useEffect(() => {
    async function fetchlist() {
      AxiosInstance.get(`users/batches/changes_new/${batchCode}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((data) => {
          let payload = data?.data?.batchData;

          let trainersandtrackerdata = data?.data?.usersdata?.map((stud) => {
            return {
              _id: stud?._id,
              role: stud?.role,
              username: stud?.username,
              number: stud?.number,
              studentNotification: stud?.studentNotification
                ? stud?.studentNotification
                : 0,
              lastmsg: stud?.lastmsg?.msg
                ? stud?.lastmsg
                : { studentNotification: 0, createdAt: 0 },
              createdAt: stud?.lastmsg?.msg ? stud?.lastmsg?.createdAt : 0,
              userleft: stud?.userleft,
            };
          });
          let batchname = data?.data?.batchData?.batchCode;
          setSelctBatchStud(payload);
          //(trainersandtrackerdata)
          setBatchStdentData(trainersandtrackerdata);
          setBatchname(batchname);
        })
        .catch((x) => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          } else if (x?.response?.status === 404) {
            setNoContent(x?.response?.status);
          }
        });
    }
    fetchlist();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("getUsers", (users) => {});
      socket.on("online_offline", (data) => {});
      socket.on("getMessage_new", (data) => {
        if (data.brodcastchat) {
          setArrivalMessage(data);
        } else {
          setArrivalMessage(data);
        }
      });
    }
  }, [socket]);
  useEffect(() => {
    setMessages((prev) => [...prev, arrivalMessage]);
  }, [particularStudent.trainersId && arrivalMessage]);

  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      setMessages((prevMessages) => prevMessages.concat(arrivalMessageFile));
      setArrivalMessageFile([]);
    }
  }, [particularStudent.trainersId && arrivalMessageFile]);

  const getconversations = async () => {
    try {
      if (particularStudent?.trainersId !== undefined) {
        socket.emit(
          "isOnline",
          {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent?.trainersId,
            device: "web",
          },
          function callback(msg) {
            setOnline(msg);
          }
        );
        //(batchCode, "code");
        socket.emit("chatwith", {
          senderId: user?._id,
          role: user?.role1,
          receiverId: particularStudent?.trainersId,
          code: batchCode,
          device: "web",
        });

        if (particularStudent.notification === true) {
          socket.emit("msgRead_new", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent.trainersId,
            seen: true,
            code: batchname,
            count: particularStudent?.studentnoti,
            type: "batch",
            device: "web",
          });
        } else {
          socket.emit("msgRead_new", {
            senderId: user?._id,
            role: user?.role1,
            receiverId: particularStudent.trainersId,
            seen: false,
            code: batchname,
            count: particularStudent?.studentnoti,
            type: "batch",
            device: "web",
          });
        }

        const res = await AxiosInstance.post(
          `/chat/msg/new/allmsg?scroll=${msgScroll}`,
          {
            senderId: user._id,
            receiverId: particularStudent?.trainersId,
            userId: particularStudent?.trainersId,
            studentId: user._id,
            code: batchCode,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        res?.data?.msgs?.reverse();
        setMessageCount(res?.data?.msgscount);
        setLengthMsg(res?.data?.msgs?.length);

        if (res?.data?.msgs?.length > 0 && msgScroll > 0) {
          let mess = res?.data?.msgs;
          setMessages([...mess, ...messages]);
          setLoading(false);
        } else if (res?.data?.msgs?.length > 0 && msgScroll <= 0) {
          // socket.on("msgSeen", data1 => {
          //   setMsgSeen(data1);

          //   res.data.msgs.map(x => {
          //     return { ...x, tick: true };
          //   });
          // });

          for (
            let index = 1;
            index <= res?.data?.notification?.usersNotification;
            index++
          ) {
            const element =
              index > res?.data?.msgs?.length
                ? res?.data?.msgs[res?.data?.msgs?.length - 1]
                : res?.data?.msgs[res?.data?.msgs?.length - index];

            element.tick = false;
          }
          setMessages(res.data.msgs);
          setLoading(false);
        } else if (res?.data?.msgs?.length <= 0) {
          setMessages(res.data.msgs);

          setLoading(false);
        }
      }
      return () => {
        socket.off("msgSeen");
      };
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
    }
  };

  useEffect(() => {
    // Event listener for receiving messages
    const handleNewMessage = (message) => {
      setMessages((prevMessages) =>
        prevMessages.map(
          (x) => (x.tick === false ? { ...x, tick: true } : x) // Leave the object unchanged if tick is already true
        )
      );
    };

    // Add the event listener
    socket.on("msgSeen", handleNewMessage);

    // Cleanup function
    return () => {
      socket.off("msgSeen", handleNewMessage); // Remove the listener to avoid memory leaks
    };
  }, [socket]);

  useEffect(() => {
    getconversations();
  }, [user._id && particularStudent?.trainersId, msgseen, msgScroll]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Call the function when messages change
  }, [messages]);

  let handelSubmit = async (e) => {
    e?.preventDefault();
    // //(e.target.value)
    if (newMessage.trim() !== "") {
      setLoading(true);
      {
        reply
          ? socket?.emit(
              "sendMessage_new",
              {
                senderId: user._id,
                receiverId: particularStudent?.trainersId,
                text: newMessage,
                role: user?.role1,
                code: "",
                type: "batch",
                device: "web",
                poll: null,
                replayback: {
                  message: selectedMessages[0]?.messages?.text
                    ? selectedMessages[0]?.messages?.text
                    : null,
                  file: selectedMessages[0]?.messages?.files
                    ? selectedMessages[0]?.messages?.files
                    : null,
                },
              },
              function callback(msg) {
                setReply(false);
                setSelectedMessages([]);

                if (msg.read === false) {
                  msg.tick = false;
                  setMessages([...messages, msg]);
                  setLoading(false);
                } else {
                  setMessages([...messages, msg]);
                  setLoading(false);
                }
              }
            )
          : socket.emit(
              "sendMessage_new",
              {
                senderId: user._id,
                receiverId: particularStudent?.trainersId,
                text: newMessage,
                role: user?.role1,
                code: "",
                type: "batch",
                device: "web",
                poll: null,
              },
              function callback(msg) {
                if (msg.read === false) {
                  msg.tick = false;

                  setMessages([...messages, msg]);
                  setLoading(false);
                } else {
                  setMessages([...messages, msg]);
                  setLoading(false);
                }
              }
            );
      }
      try {
        setNewMessage("");
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    } else {
      //("first");
    }
  };
  //(messages);
  let handelSubmitButton = (e) => {
    handelSubmit();
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };
  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard

    // Handle the pasted text as needed
    // //("Pasted text:", pastedText);
    setNewMessage(pastedText);
  };
  let HandleLoadMessages = () => {
    setMsgScroll(msgScroll + lengthmsg);
  };

  return (
    <>
      <section id={Styles.chatBoxSection}>
        {noContent === 404 ? (
          <div className={Styles.Nodata}>No Such Data</div>
        ) : (
          <article>
            {/* chat users name displays here */}
            <aside className={Styles.chatMenu}>
              <StuConversation
                batchTrainer={batchStdentData}
                setParticularStudent={setParticularStudent}
                trainername={selctBatchStud}
                batchname={batchname}
                messages={messages}
                setStop={setStop}
                setIsPlaying={setIsPlaying}
                setRecordedAudio={setRecordedAudio}
                setIsPlaybackActive={setIsPlaybackActive}
                setIsAudioVisible={setIsAudioVisible}
                setRecordedChunks={setRecordedChunks}
                setMsgScroll={setMsgScroll}
                setMessages={setMessages}
                particularStudent={particularStudent}
                setSelectedMessages={setSelectedMessages}
                setReply={setReply}
              />
            </aside>
            {/* view message part stars here */}
            <aside
              className={Styles.chatBox}
              style={{
                backgroundImage: `url('${bg6}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}>
              <div className={Styles.chatBoxWrapper}>
                {particularStudent?.trainersId ? (
                  <>
                    <div className={Styles.chatBoxTop}>
                      <StudentName
                        name={particularStudent?.trainerName}
                        online={online}
                        setOnline={setOnline}
                      />

                      <div
                        className={Styles.chatBoxStore}
                        id="scrollableDiv"
                        ref={scrollRef}
                        style={{
                          height: "78vh",
                          overflow: "auto",
                          // flexDirection: "column-reverse",
                        }}>
                        {/* <InfiniteScroll
                        dataLength={messages.length}
                        next={Loadmore}
                        // hasMore={batchStudentList?.length < numberofStudent + 50}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                      > */}
                        {messageCount != msgScroll + lengthmsg &&
                          messages.length > 0 && (
                            <div className={Styles.SpinnerBlock}>
                              {" "}
                              <button
                                onClick={HandleLoadMessages}
                                className={Styles.Spinner}>
                                <ImSpinner11 />
                              </button>
                            </div>
                          )}
                        {messages.length > 0 ? (
                          messages.map((m, ind) => (
                            <Fragment key={ind}>
                              <StuMessage
                                particularStudent={particularStudent}
                                message={m}
                                index={ind}
                                own={m?.sender === user?._id}
                                setReply={setReply}
                                reply={reply}
                                selectedMessages={selectedMessages}
                                onClick={handleMessageClick}
                                setSelectedMessages={setSelectedMessages}
                              />
                            </Fragment>
                          ))
                        ) : (
                          <span className={Styles.noConversation}>
                            <p style={{ color: "#f16f1d" }}>No Messages</p>
                          </span>
                        )}

                        {/* </InfiniteScroll> */}
                      </div>
                    </div>
                    {/* <div ref={scrollRef} style={{ display: "none" }}>
                    top
                  </div> */}
                    {particularStudent?.userleft === false && (
                      <form
                        onSubmit={handelSubmit}
                        className={Styles.FormBlock}>
                        {reply && (
                          <div className={Styles.replyBlock}>
                            <div className={Styles.StatusText1}>
                              {" "}
                              {selectedMessages && (
                                <>
                                  {/* <div>Reply</div> */}
                                  <div className={Styles.copiedText}>
                                    {selectedMessages[0]?.messages?.text
                                      ? selectedMessages[0]?.messages?.text?.slice(
                                          0,
                                          180
                                        )
                                      : selectedMessages[0]?.messages?.files[0]
                                          ?.filename}
                                  </div>
                                  <div
                                    className={Styles.CrossMark}
                                    onClick={() => CancelReply()}>
                                    X
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        <div className={Styles.chatBoxBottom}>
                          <input
                            name="message"
                            id="message"
                            className="chatMessageInput"
                            placeholder="write something..."
                            onChange={(e) => {
                              setNewMessage(e.target.value);
                            }}
                            value={newMessage}
                            onKeyPress={handleKeyPress}
                            onPaste={handlePaste}
                          />
                          <aside
                            style={{ position: "relative" }}
                            className={Styles.UploadfileContainer}>
                            <>
                              {isRecording ? (
                                ""
                              ) : stop ? (
                                ""
                              ) : (
                                <ButtonToolbar>
                                  <ImAttachment
                                    onClick={handleOpen}></ImAttachment>
                                </ButtonToolbar>
                              )}

                              <Modal
                                // backdrop={backdrop}
                                keyboard={false}
                                open={open}
                                onClose={handleClose}>
                                <Modal.Header>
                                  <Modal.Title style={{ color: "#f16f1d" }}>
                                    File Upload
                                  </Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                  <FileUpload
                                    PStudent={particularStudent}
                                    setMessages={setMessages}
                                    messages={messages}
                                    setOpen={setOpen}
                                    batchname={batchname}
                                    setArrivalMessage={setArrivalMessage}
                                    setArrivalMessageFile={
                                      setArrivalMessageFile
                                    }
                                    reply={reply}
                                    selectedMessages={selectedMessages}
                                    setReply={setReply}
                                    setSelectedMessages={setSelectedMessages}
                                  />
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                              </Modal>
                            </>
                          </aside>
                          <aside className={Styles.AudioBlock}>
                            <div>
                              {!stop && !isRecording && !isPlaying && (
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{"Start"}</Tooltip>}>
                                  <main
                                    className={Styles.StartAudio}
                                    onClick={handleStartRecording}
                                    disabled={isRecording}>
                                    <span>
                                      {/* start */}
                                      <BsFillMicFill />
                                    </span>
                                  </main>
                                </Whisper>
                              )}
                              <div
                                className={`${Styles.recordingAnimation} ${
                                  isRecording ? Styles.active : ""
                                }`}></div>
                              {isRecording && (
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{"Stop"}</Tooltip>}>
                                  <main
                                    className={Styles.StopAudio}
                                    onClick={handleStopRecording}
                                    disabled={!isRecording}>
                                    <span>
                                      {/* stop */}
                                      <BsFillMicMuteFill />
                                    </span>
                                  </main>
                                </Whisper>
                              )}
                              {stop && (
                                <Whisper
                                  followCursor
                                  speaker={<Tooltip>{"Play"}</Tooltip>}>
                                  <main
                                    className={Styles.PlayAudio}
                                    onClick={handlePlayButtonClick}
                                    disabled={isRecording}>
                                    <span>
                                      {/* play */}
                                      <BsFillPlayCircleFill />
                                    </span>
                                  </main>
                                </Whisper>
                              )}
                              {/* {stop &&<p>{recordingStatus}</p>} */}
                              {audioPlayer}
                            </div>
                          </aside>
                          {stop && (
                            <div className={Styles.SendAudioBlock}>
                              <Whisper
                                followCursor
                                speaker={<Tooltip>{"Delete"}</Tooltip>}>
                                <span
                                  onClick={handleDeleteButtonClick}
                                  className={Styles.DeleteAudio}>
                                  <AiFillDelete />
                                </span>
                              </Whisper>
                              <span
                                onClick={HandleAudio}
                                className={Styles.SendAudio}>
                                send
                                {/* <AiOutlineSend /> */}
                              </span>
                            </div>
                          )}
                          {!stop && (
                            <div>
                              {loading || isRecording ? (
                                <button
                                  className="chatSubmitButton"
                                  disabled
                                  onClick={handelSubmitButton}>
                                  <div className={Styles.loader}>
                                    <RotatingLines
                                      strokeColor="#f16f1d"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="20"
                                      visible={true}
                                      // z-index={111}
                                    />
                                  </div>
                                </button>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    className="chatSubmitButton"
                                    onClick={handelSubmitButton}>
                                    Send
                                  </button>
                                  <button
                                    type="submit"
                                    style={{ display: "none" }}
                                    className="chatSubmitButton"
                                    onClick={handelSubmitButton}>
                                    Send
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </form>
                    )}
                  </>
                ) : (
                  <span className={Styles.noConversationText}>
                    <p style={{ color: "#f16f1d" }}>
                      Open a conversation to start a chat.
                    </p>
                  </span>
                )}
              </div>
            </aside>
          </article>
        )}
      </section>
    </>
  );
};

export default StudentChatComp;
