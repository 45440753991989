import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import UserBroadcastComp from "./Broadcast/UserBroadcastComp";
import UserChatComp from "./Chat/UserChatComp";
import { globalContext } from "./UsersContext";
import { toast } from "react-toastify";

const SelectedBatchList = () => {
  let navigate = useNavigate();
  let { switchComp } = useContext(globalContext);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  let { batchCode } = useParams();

  useEffect(() => {
    if (user) {
      async function fetchlist() {
        AxiosInstance.get(`users/batches/changes_new/${batchCode}`, {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        })
          .then(data => {
      
            let studentData = data?.data?.studentsCount;
            if (studentData <= 0) {
              toast.error("No students present");
              setTimeout(() => {
                navigate(-1);
              }, 100);
            }
          })
          .catch(x => {
            if (x?.response?.status === 403) {
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("device");
              Cookies.remove("name", { path: "", domain: ".qspiders.com" });
              navigate("/");
              Cookies.remove("student", { path: "", domain: ".qspiders.com" });
              Cookies.remove("visitedHomePage", {
                path: "",
                domain: ".qspiders.com",
              });
            } else if (x?.response?.status === 404) {
              toast.error("this group is closed");
              setTimeout(() => {
                navigate(-1);
              }, 100);
            }
          });
      }
      fetchlist();
    }
  }, [user]);

  return (
    <div>
      {user?.TOKEN ? (
        switchComp ? (
          <UserChatComp />
        ) : (
          <UserBroadcastComp />
        )
      ) : (
        navigate("/")
      )}
    </div>
  );
};

export default SelectedBatchList;
