import AxiosInstance from "../../api/AxiosInstance";

const fetchImageUrl = async (imgPath, token) => {
  //   try {
  //     const response = await AxiosInstance.get(`/${imgPath}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       responseType: "arraybuffer",
  //     });

  //     // Convert the binary data to a base64 encoded string
  //     const base64 = btoa(
  //       new Uint8Array(response.data).reduce(
  //         (data, byte) => data + String.fromCharCode(byte),
  //         ""
  //       )
  //     );

  //     // Create a data URL
  //     const dataUrl = `data:${response.headers[
  //       "content-type"
  //     ].toLowerCase()};base64,${base64}`;

  //     return dataUrl;
  try {
    if (imgPath != undefined) {
      const response = await AxiosInstance.get(`/${imgPath}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const url = URL.createObjectURL(blob);

      return { url, contentType };
    }
  } catch (error) {
    // console.error("Error fetching image URL:", error);
    return null;
  }
};

export default fetchImageUrl;
