import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { Button, Modal } from "rsuite";
import { toast } from "react-toastify";
import Styles from "../Student/_user.module.css";
import { BddtAxios, BddtFile } from "../../api/AxiosInstance";
import { useParams } from "react-router-dom";
import {
  Grid,
  Pagination,
  Paper,
  Table,
  Button as MuiButton,
  FormControl,
  Autocomplete,
  TextField,
  Divider,
} from "@mui/material";
import { TableBody, TableCell, TableContainer } from "@mui/material";
import { TableHead, TableRow, styled, tableCellClasses } from "@mui/material";
import { SocketContext } from "../../ioContext/socketIo";
import Style from "./batch.module.css";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const RequirementList = () => {
  let { id } = useParams();

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const [openCourse, setopenCourse] = useState(false);
  let [requirement, setRequirement] = useState([]);
  let [defaultList, setDefaultList] = useState([]);
  let [loadingReq, setLoadingReq] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 25,
    page: 1,
    load: false,
    length: 1,
  });
  let [selectedData, setSelectedData] = useState([]);
  let [sid, setSid] = useState("");
  const [rows, setRows] = React.useState(0);
  let [pag, setPag] = useState("");
  let [color, setColor] = useState([]);
  let [reasonsList, setReasonsList] = useState([]);
  let [reasons, setReasons] = useState(null);
  let [remove, setRemove] = useState(false);
  let [list, setList] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);

  // requirement search
  let [searchReqTrue, setSearchReqTrue] = useState(false);

  let [inputName, setInputName] = useState("");
  let [inputNameReq, setInputNameReq] = useState("");
  const socket = useContext(SocketContext);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  function closeCourse() {
    setopenCourse(false);
  }

  let HandleClearSearchReq = () => {
    setSearchReqTrue(false);
    setInputName("");
    setInputNameReq("");
    setRequirement(defaultList.results);
    setPagination({
      ...pagination,
      length: defaultList.total,
      load: defaultList.total > 25 ? false : null,
      page: 1,
    });
  };

  useEffect(() => {
    setLoadingReq(true);
    async function fetchlist() {
      BddtAxios.get(`/hr/reqlistqtalk/?stid=${id}`, {
        headers: {
          Authorization: `Token ${user?.hr_Token}`,
        },
      })
        .then(x => {
          let data = x?.data?.results;
          let totalpage = x?.data?.total;

          setPag(totalpage);
          setPagination({
            ...pagination,
            length: x?.data?.total,
            load: x?.data?.total > 25 ? false : null,
            page: 1,
          });
          setRequirement(data);
          setDefaultList(x.data);
          setLoadingReq(false);
        })
        .catch(x => {
          setLoadingReq(null);
        });
    }
    fetchlist();
  }, [remove]);

  const handleChangePage = async (_, value) => {
    const compName = inputName ? `&company_name=${inputName}` : "";
    const requirementName = inputNameReq ? `&req_code=${inputNameReq}` : "";
    const newPage = value;
    setPagination({ ...pagination, load: true });
    try {
      const { data } = await BddtAxios.get(
        `/hr/reqlistqtalk/?stid=${id}&page=${newPage}${compName}${requirementName}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      setRequirement(data.results);
      setPagination({
        ...pagination,
        length: data.total,
        page: value,
        load: false,
      });
    } catch (error) {
      toast.error("something went wrong");
      setPagination({ ...pagination, load: false });
    }
  };

  const handleSearchFunction = async () => {
    const compName = inputName ? `&company_name=${inputName}` : "";
    const requirementName = inputNameReq ? `&req_code=${inputNameReq}` : "";
    setPagination({ ...pagination, load: true });
    try {
      let { data } = await BddtAxios.get(
        `/hr/reqlistqtalk/?stid=${id}${compName}${requirementName}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );

      setRequirement(data.results);
      setPagination({
        ...pagination,
        length: data.total,
        page: 1,
        load: data.total > 25 ? false : null,
      });
    } catch (error) {
      toast.error(error?.response?.data?.error || "something went wrong");
      setPagination({ ...pagination, load: false });
    }
  };

  let HandleAddRequirement = () => {
    let payload = { student: id, split: sid, comment: "web" };

    async function students() {
      await BddtFile.post(`hr/addstudentreq/`, payload, {
        headers: {
          Authorization: `Token ${user?.hr_Token}`,
        },
      })
        .then(x => {
          toast.success(x.data.message, {
            position: "top-right",
          });
          closeCourse();
          setOpen(false);
          setRemove(!remove);
        })
        .catch(x => {
          setOpen(false);
          toast.error(x?.response?.data?.message, {
            position: "top-right",
          });
        });
    }
    students();
  };
  let HandleRemoveRequirement = () => {
    async function students() {
      await BddtFile.get(`/hr/remove-st-status/`, {
        headers: {
          Authorization: `Token ${user?.hr_Token}`,
        },
      })
        .then(x => {
          let data = x.data.results;
          let eee = data.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });
          setReasonsList(eee);
          setList(true);
        })
        .catch(x => {
          setIsLoadingBranch(false);

          setList(false);
        })
        .finally(() => {
          setIsLoadingBranch(false);
        });
    }
    students();
  };

  const handleMenuOpenReasosns = () => {
    // if (reasons.length === 0 && !isLoadingBranch) {
    setIsLoadingBranch(true);
    HandleRemoveRequirement();
    // }
  };

  let handleReason = e => {
    if (e?.value) {
      setReasons(e.value);
    } else {
      setReasons("");
    }
  };
  let RemoveFromReq = () => {
    if (reasons === null) {
      toast.warn("Please Select Reason", {});
    } else {
      let payload = { remove_reason_id: reasons.value };
      async function students() {
        await BddtFile.post(
          `/hr/removestudent/?strequirement_id=${color.streq_id}`,
          payload,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        )
          .then(x => {
            toast.success(x.data.message, {
              position: "top-right",
            });
            closeCourse();
            setRemove(!remove);
          })
          .catch(x => {
            toast.error(x?.response?.data?.message, {
              position: "top-right",
            });
          });
      }
      students();
    }
  };

  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification_new", handleNotification);

    return () => {
      socket.off("notification_new", handleNotification);
    };
  }, []);

  return (
    <section id={Styles.RequirementBlock1}>
      {loadingReq === null ? (
        <h3 style={{ width: "100%", textAlign: "center" }}>
          Something went wrong
        </h3>
      ) : loadingReq ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <Paper
          elevation={12}
          sx={{
            width: "98%",
            overflow: "hidden",
            margin: "0 auto",
            position: "relative",
            marginTop: "20px",
          }}
          className={Style.studentListTable}
        >
          <Grid
            container
            spacing={1}
            className={Style.filterBlock}
            alignItems="center"
          >
            {" "}
            <Grid item md={6}>
              <h4 style={{ color: "#fff", textAlign: "center" }}>
                Open Requirement List
              </h4>
            </Grid>
            <Grid item md={2} position="relative">
              <span className={Style.searchIcon}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  stroke="#F8931F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </span>
              <input
                name="inputNameReq"
                style={{ fontWeight: "bold" }}
                id="inputNameReq"
                value={inputNameReq}
                className={Style.studentNameList}
                type="text"
                placeholder="Enter Requirement Name"
                onChange={e => setInputNameReq(e.target.value)}
              />
            </Grid>
            <Grid item md={2} position="relative">
              <span className={Style.searchIcon}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  stroke="#F8931F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </span>
              <input
                type="text"
                name="inputName"
                id="inputName"
                className={Style.studentNameList}
                value={inputName}
                placeholder="Enter Company Name"
                onChange={e => setInputName(e.target.value)}
                style={{ fontWeight: "bold" }}
              />
            </Grid>
            <Grid item md={1}>
              {searchReqTrue === false ? (
                <MuiButton
                  size="small"
                  type="button"
                  color="warning"
                  sx={{
                    background: "#F87000",
                    color: "#111",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "#F87000",
                      color: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    if (
                      inputNameReq.trim().length < 3 &&
                      inputName.trim().length < 3
                    ) {
                      toast.warn(
                        "Please Enter min 3 char of Requirement/Company name to search"
                      );
                    } else {
                      setSearchReqTrue(true);
                      handleSearchFunction();
                    }
                  }}
                >
                  Search
                </MuiButton>
              ) : (
                <MuiButton
                  sx={{ fontWeight: "bold" }}
                  size="small"
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={HandleClearSearchReq}
                >
                  Reset
                </MuiButton>
              )}
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{ height: "75vh", borderRadius: "6px" }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ borderCollapse: "collapse" }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" width="18%">
                    Requirement Name
                  </StyledTableCell>
                  <StyledTableCell align="center">Company Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Probable interview date
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Interview Close date
                  </StyledTableCell>
                  <StyledTableCell align="center">Added Status</StyledTableCell>
                  <StyledTableCell align="center">
                    Requirement details
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.load ? (
                  "Loading..."
                ) : requirement?.length < 1 ? (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={6}>
                      No Data Found
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  <>
                    {requirement?.length > 0 &&
                      requirement?.map((ele, ind) => {
                        return (
                          <StyledTableRow
                            key={ind + 1}
                            className={
                              ele?.req_match?.matching === false
                                ? `${Styles.noColor}`
                                : `${Styles.Green}`
                            }
                          >
                            <StyledTableCell
                              scope="row"
                              // size="small"
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {ele?.requirement?.req_code}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              // size="small"
                              style={{
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {ele?.requirement?.company_details_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.requirement?.tentative_date}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.requirement?.close_date}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele?.student_count?.st_count}/{ele.count}{" "}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <button
                                title="view"
                                style={{
                                  background: "transparent",
                                  border: "none",
                                }}
                                onClick={e => {
                                  setopenCourse(true);
                                  // setRemove(false);
                                  setSelectedData(ele.requirement);
                                  setColor(ele.req_match);
                                  setSid(ele.id);
                                }}
                              >
                                <svg
                                  width={22}
                                  height={22}
                                  viewBox="0 0 256 256"
                                >
                                  <path
                                    fill="#f16f1d"
                                    d="M251 123.13c-.37-.81-9.13-20.26-28.48-39.61C196.63 57.67 164 44 128 44S59.37 57.67 33.51 83.52C14.16 102.87 5.4 122.32 5 123.13a12.08 12.08 0 0 0 0 9.75c.37.82 9.13 20.26 28.49 39.61C59.37 198.34 92 212 128 212s68.63-13.66 94.48-39.51c19.36-19.35 28.12-38.79 28.49-39.61a12.08 12.08 0 0 0 .03-9.75m-46.06 33C183.47 177.27 157.59 188 128 188s-55.47-10.73-76.91-31.88A130.36 130.36 0 0 1 29.52 128a130.45 130.45 0 0 1 21.57-28.11C72.54 78.73 98.41 68 128 68s55.46 10.73 76.91 31.89A130.36 130.36 0 0 1 226.48 128a130.45 130.45 0 0 1-21.57 28.12ZM128 84a44 44 0 1 0 44 44a44.05 44.05 0 0 0-44-44m0 64a20 20 0 1 1 20-20a20 20 0 0 1-20 20"
                                  ></path>
                                </svg>
                              </button>
                            </StyledTableCell>
                            {openCourse === true && (
                              <Modal
                                keyboard={false}
                                open={openCourse}
                                onClose={closeCourse}
                                onExited={() => {
                                  setRows(0);
                                }}
                              >
                                <main className={Styles.ModalReq}>
                                  <Modal.Header className={Styles.ReqHeader}>
                                    <Modal.Title>
                                      <h3
                                        className={Styles.ModalHead}
                                        style={{ textAlign: "center" }}
                                      >
                                        Requirement Details
                                      </h3>
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div
                                      className={`${Styles.ReqListView} ${Styles.ReqListView1}`}
                                    >
                                      <h5>
                                        <b> Designation : </b>
                                        <span className={Styles.datareq}>
                                          {selectedData?.designation[0]?.name}
                                        </span>
                                      </h5>
                                      <h5>
                                        <b>Interview Location : </b>
                                        <span className={Styles.datareq}>
                                          {" "}
                                          {
                                            selectedData?.interview_location
                                              ?.name
                                          }
                                        </span>
                                      </h5>
                                      {selectedData?.location?.length > 0 && (
                                        <h5>
                                          <b> Job Location : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.location
                                              ?.map(x => x?.name)
                                              ?.join(", ")}
                                          </span>
                                        </h5>
                                      )}
                                      <h5>
                                        <b>Interview Rounds : </b>
                                        <span className={Styles.datareq}>
                                          {selectedData?.rounds
                                            ?.map(x => x.name)
                                            ?.join(", ")}
                                        </span>
                                      </h5>
                                      {selectedData?.ctc === null ? (
                                        ""
                                      ) : (
                                        <h5>
                                          <b> CTC : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.ctc}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.deposite === true ? (
                                        <h5>
                                          <b>Deposit : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.amount}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {selectedData[0]?.bond === true ? (
                                        <h5>
                                          <b>Bond : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.years}- years
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {selectedData?.certification_sub ===
                                      true ? (
                                        <h5>
                                          <b> Certificate : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.certificate
                                              ?.map(x => x.name)
                                              ?.join(", ")}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}

                                      {selectedData?.shift[0]?.name?.length >
                                        0 && (
                                        <h5>
                                          <b> Shift : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.shift
                                              ?.map(x => x?.name)
                                              ?.join(", ")}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.subject ? (
                                        <h5>
                                          <b> Skills : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.subject
                                              ?.map(x => x?.name)
                                              ?.join(", ")}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {selectedData?.otherskill === true && (
                                        <h5>
                                          <b> Other Subject : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.other_skill}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.interview?.length > 0 && (
                                        <h5>
                                          <b> Interview type : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.interview?.map(
                                              x => x?.name
                                            )}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.m_hiring !== null && (
                                        <h5>
                                          <b> Mode of Interview : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.m_hiring?.name}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.deposite === true ? (
                                        <h5>
                                          <b> Deposite : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.amount}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}

                                      {selectedData?.bond === true && (
                                        <h5>
                                          <b> Bond : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {"True"}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.certificate?.length >
                                        0 && (
                                        <h5>
                                          <b>Certificate : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.certificate.map(
                                              x => x.name
                                            )}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.other === true && (
                                        <h5>
                                          <b> Other_Certificate : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.other_certificate}
                                          </span>
                                        </h5>
                                      )}
                                      <h5>
                                        <b> position : </b>
                                        <span className={Styles.datareq}>
                                          {" "}
                                          {selectedData?.position}
                                        </span>
                                      </h5>
                                      {selectedData?.qualification_type !==
                                        "" && (
                                        <h5>
                                          <b> Qualification : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.qualification_type}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.yop !== "" && (
                                        <h5>
                                          <b>Year of passing : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.yop}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.gender?.length > 0 && (
                                        <h5>
                                          <b> Gender : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.gender
                                              ?.map(x => {
                                                return x?.name;
                                              })
                                              ?.join(", ")}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.tenth_percent !== "" && (
                                        <h5>
                                          <b>
                                            10<sup>th</sup> <small>%</small> :{" "}
                                          </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.tenth_percent}
                                          </span>
                                        </h5>
                                      )}

                                      {selectedData?.twelfth_percent !== "" && (
                                        <h5>
                                          <b>
                                            12<sup>th</sup> <small>%</small> :{" "}
                                          </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.twelfth_percent}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.diploma_percent !==
                                        null && (
                                        <h5>
                                          <b>
                                            Diploma <small>%</small> :
                                          </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.diploma_percent}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.degree_percent !==
                                        null && (
                                        <h5>
                                          <b>
                                            {" "}
                                            Degree <small>%</small> :{" "}
                                          </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.degree_percent}
                                          </span>
                                        </h5>
                                      )}

                                      {selectedData?.any_degree === true ? (
                                        <h5>
                                          <b> Degree : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            Any Degree
                                          </span>
                                        </h5>
                                      ) : selectedData?.degree?.length > 0 ? (
                                        <h5>
                                          <b> Degree : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.degree
                                              ?.map(x => {
                                                return x?.name;
                                              })
                                              .join(", ")}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {selectedData?.any_degree_stream ===
                                      true ? (
                                        <h5>
                                          <b> Degree Stream : </b>
                                          <span className={Styles.datareq}>
                                            Any Degree Stream
                                          </span>
                                        </h5>
                                      ) : selectedData?.degree_stream?.length >
                                        0 ? (
                                        <h5>
                                          <b> Degree Stream : </b>
                                          <span className={Styles.datareq}>
                                            {selectedData?.degree_stream
                                              ?.map(x => {
                                                return x?.name;
                                              })
                                              .join(", ")}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {selectedData?.masters_percent !==
                                        null && (
                                        <h5>
                                          <b>
                                            Masters <small>%</small> :{" "}
                                          </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.masters_percent}
                                          </span>
                                        </h5>
                                      )}
                                      {selectedData?.any_master_degree ===
                                      true ? (
                                        <h5>
                                          <b> Master Degree : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            Any Master Degree
                                          </span>
                                        </h5>
                                      ) : selectedData?.master_degree?.length >
                                        0 ? (
                                        <h5>
                                          <b> Master Degree : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.master_degree
                                              ?.map(x => {
                                                return x?.name;
                                              })
                                              .join(", ")}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                      {selectedData?.any_master_stream ===
                                      true ? (
                                        <h5>
                                          <b> Master Stream : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            Any Master Stream
                                          </span>
                                        </h5>
                                      ) : selectedData?.master_stream?.length >
                                        0 ? (
                                        <h5>
                                          <b>Master Stream : </b>
                                          <span className={Styles.datareq}>
                                            {" "}
                                            {selectedData?.master_stream
                                              ?.map(x => {
                                                return x?.name;
                                              })
                                              .join(", ")}
                                          </span>
                                        </h5>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Modal.Body>
                                  <Divider />

                                  <Modal.Footer
                                    style={{
                                      padding: "20px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {color?.added === true ? (
                                      <>
                                        <>
                                          <div className={Styles.reasons}>
                                            <h6
                                              htmlFor="reasons"
                                              style={{ color: "#f16f1d" }}
                                            >
                                              Select reason to remove from
                                              requirement
                                            </h6>
                                            <div
                                              className={
                                                Styles.dropdownforreason
                                              }
                                            >
                                              {/* <Select
                                                onMenuOpen={
                                                  handleMenuOpenReasosns
                                                }
                                                isLoading={isLoadingBranch}
                                                options={reasonsList}
                                                name="reasons"
                                                onChange={handleReason}
                                              /> */}
                                              <FormControl
                                                fullWidth
                                                className={Styles.selectField}
                                                style={{
                                                  marginTop: "5px",
                                                  marginBottom: "5px",
                                                }}
                                              >
                                                <Autocomplete
                                                  freeSolo
                                                  options={reasonsList}
                                                  onOpen={
                                                    handleMenuOpenReasosns
                                                  }
                                                  value={reasons}
                                                  onChange={(
                                                    event,
                                                    newValue
                                                  ) => {
                                                    setReasons(newValue);
                                                  }}
                                                  color="warning"
                                                  size="small"
                                                  renderInput={params => (
                                                    <TextField
                                                      {...params}
                                                      label="Select Reason"
                                                    />
                                                  )}
                                                />
                                              </FormControl>
                                            </div>
                                            <p
                                              className={
                                                errors.reasons
                                                  ? `${Styles.errrr}`
                                                  : `${Styles.gapbtw}`
                                              }
                                            ></p>
                                            {errors.reasons && (
                                              <p className={Styles.errors}>
                                                {errors.reasons}
                                              </p>
                                            )}
                                          </div>
                                          <MuiButton
                                            variant="contained"
                                            color="warning"
                                            size="small"
                                            style={{ float: "right" }}
                                            // className={Styles.redColor}
                                            onClick={e => {
                                              RemoveFromReq();
                                            }}
                                          >
                                            Remove
                                          </MuiButton>
                                        </>
                                      </>
                                    ) : (
                                      <MuiButton
                                        style={{ float: "right" }}
                                        color="success"
                                        variant="contained"
                                        size="small"
                                        onClick={e => {
                                          setOpen(true);
                                        }}
                                      >
                                        Add To Requirement
                                      </MuiButton>
                                    )}
                                  </Modal.Footer>
                                </main>
                              </Modal>
                            )}
                          </StyledTableRow>
                        );
                      })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            backdrop="static"
            role="alertdialog"
            open={open}
            onClose={handleClose}
            size="xs"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Body style={{ fontSize: "16px" }}>
              <h6>Are you sure you want to add student to the requirement !</h6>
            </Modal.Body>
            <Modal.Footer>
              <MuiButton
                onClick={HandleAddRequirement}
                color="warning"
                size="small"
                variant="contained"
              >
                Ok
              </MuiButton>
              &nbsp;&nbsp;
              <MuiButton
                onClick={handleClose}
                size="small"
                variant="outlined"
                color="warning"
              >
                Cancel
              </MuiButton>
            </Modal.Footer>
          </Modal>
          {pagination.load !== null && (
            <Grid
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              <Pagination
                count={Math.ceil(pagination.length / pagination.limit)}
                color="warning"
                defaultPage={1}
                disabled={pagination.load}
                rowsperpage={pagination.limit}
                siblingCount={1}
                showLastButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                showFirstButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                page={pagination.page}
                onChange={handleChangePage}
              />
            </Grid>
          )}
        </Paper>
      )}
    </section>
  );
};

export default RequirementList;
